import React, { Component } from "react";
import SubHeadComponent from "../../common/SubHead.component";
import PlayerListContComponent from "../../common/PlayerListCont.component";

import * as GameService from "../../../../services/Game.service";
import * as PlayerHelper from "../../../../helpers/Player.helper";

import AnalyticsContext from "../../../../context/AnalyticsContext";
import * as CommonService from "../../../../services/Common.service";

import SubMenuComponent from "../../common/SubMenu.component";

class ListContainer extends Component {
  static contextType = AnalyticsContext;
  constructor(props) {
    super(props);

    const subMenu = this.props.match.path.split("/")[2];
    const subMenuName = this.getSubMenuNameByType(subMenu);
    const positionInitial = PlayerHelper.getPositionInitialByType(subMenu);

    this.state = {
      subMenu,
      subMenuName,

      positionInitial,
      list: [],
      onChangeKeyWord: null,
    };
  }

  componentDidMount = async (_) => {
    try {
      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      this.context.action(currentAnalyticsVal);

      currentAnalyticsVal.deps2 = "02";
      let deps3 = "";

      switch (this.state.positionInitial) {
        case "투":
          deps3 = "02";
          break;

        case "포":
          deps3 = "03";
          break;

        case "내":
          deps3 = "04";
          break;

        case "외":
          deps3 = "05";
          break;

        case "군":
          deps3 = "06";
          break;

        default:
          break;
      }

      currentAnalyticsVal.deps3 = deps3;

      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.currentUrl = window.location.href;

      await CommonService.insertAnalytics(this.context.state);

      let list = [];
      if (this.state.positionInitial !== "군") {
        const result = await GameService.getPlayerList(
          this.state.positionInitial
        );
        list = result.list;
      } else {
        const result = await GameService.getMilitaryEnlistedList(2019);
        list = result.playerList;
      }
      // console.log('result.list', result);
      // const list = result.list;
      this.setState({ list });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) {
      this.updateList();
    }
  };

  getSubMenuNameByType = (type) => {
    switch (type) {
      case "pitcher":
        return "투수";
      case "catcher":
        return "포수";
      case "infielder":
        return "내야수";
      case "outfielder":
        return "외야수";
      case "military-enlisted":
        return "군입대선수";
      default:
        return "";
    }
  };

  updateList = async (_) => {
    try {
      const subMenu = this.props.match.path.split("/")[2];
      const subMenuName = this.getSubMenuNameByType(subMenu);
      const positionInitial = PlayerHelper.getPositionInitialByType(subMenu);

      const currentAnalyticsVal = this.context.state;
      const prevUrl = currentAnalyticsVal.currentUrl;
      let deps3 = "";

      switch (this.state.positionInitial) {
        case "투":
          deps3 = "02";
          break;

        case "포":
          deps3 = "03";
          break;

        case "내":
          deps3 = "04";
          break;

        case "외":
          deps3 = "05";
          break;

        case "군":
          deps3 = "06";
          break;

        default:
          break;
      }

      currentAnalyticsVal.deps3 = deps3;

      currentAnalyticsVal.prevUrl = prevUrl;
      currentAnalyticsVal.currentUrl = window.location.href;

      await CommonService.insertAnalytics(this.context.state);

      console.log("positionInitial", positionInitial);

      let list = [];
      if (positionInitial !== "군") {
        const result = await GameService.getPlayerList(positionInitial);
        list = result.list;
      } else {
        const result = await GameService.getMilitaryEnlistedList(2019);
        list = result.playerList;
      }

      this.setState({
        subMenu,
        subMenuName,
        positionInitial,
        list,
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  onClickBtnSearchPlayer = () => {
    window.location.href = `/players/search/${this.state.onChangeKeyWord}`;
  };

  onChangeSearchPlayerKeyWord = (evt) => {
    this.state.onChangeKeyWord = evt.target.value;
  };

  onEnterKey = (evt) => {
    if (evt.key === "Enter") {
      this.onClickBtnSearchPlayer();
    }
  };

  render = (_) => (
    <div className="container">
      <SubHeadComponent
        subMenu={this.state.subMenu}
        subMenuName={this.state.subMenuName}
      />
      <div className="sbHead_appView tb"></div>
      {process.env.REACT_APP_PREPARING_PLAYERS_PAGE_YN === "Y" ? (
        <div className="mb_cont sub_cont player">
          <div className="mb_contIn">
            <SubMenuComponent
              subMenu={this.state.subMenu}
              subMenuName={this.state.subMenuName}
            />
            <div style={{ marginTop: "50px" }}>
              <div className="txt clear">
                <img
                  src="/img/seasonstop_2024_pc.png"
                  alt="season_off_pc"
                  className="pc"
                />
                <img
                  src="/img/seasonstop_2024_pc.png"
                  className="mb"
                  style={{width: "100%"}}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <PlayerListContComponent
          subMenu={this.state.subMenu}
          subMenuName={this.state.subMenuName}
          uri={this.props.match.path}
          onClickBtnSearchPlayer={this.onClickBtnSearchPlayer}
          onChangeSearchPlayerKeyWord={this.onChangeSearchPlayerKeyWord}
          list={this.state.list}
          onEnterKey={this.onEnterKey}
        />
      )}
    </div>
  );
}

export default ListContainer;
