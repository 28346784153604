import React from "react";

const Latest = ({setVersion}) => {
    return <div>
        <article>
            <h4>KIA타이거즈㈜ 개인정보 처리방침</h4>
            <div className="polyBox">
                KIA타이거즈㈜(이하 '회사'라함)는 정보주체의 자유와 권리 보호를 위해 『개인정보 보호법』및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 『개인정보 보호법』제 30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
            </div>
            <div className="polyBox">
                1. 개인정보의 처리 목적
                <br/>
                2. 개인정보의 수집 항목 및 수집 방법
                <br/>
                3. 개인정보의 제3자 제공
                <br/>
                4. 개인정보의 처리위탁
                <br/>
                5. 개인정보의 처리 및 보유 기간
                <br/>
                6. 개인정보 파기절차 및 방법
                <br/>
                7. 정보주체 및 법정대리인의 권리•의무와 그 행사방법
                <br/>
                8. 개인정보 자동 수집장치의 설치/운영 및 거부에 관한 사항
                <br/>
                9. 개인정보의 안전성 확보조치
                <br/>
                10. 개인정보 보호책임자 및 담당자 안내
                <br/>
                11. 적용범위
                <br/>
                12. 개인정보 처리방침의 변경에 관한 사항
                <br/>
            </div>
        </article>
        <article>
            <h5>1. 개인정보의 처리 목적</h5>
            <p>회사는 고객의 개인정보를 수집 이용하는 목적은 다음과 같습니다. 다음의 목적 이외의 용도로 개인정보는 이용되지 않으며, 이용 목적이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
            <table className="polyTb">
                <caption>개인정보의 처리 목적</caption>
                <colgroup>
                    <col/>
                    <col/>
                </colgroup>
                <thead>
                <tr>
                    <th>구분</th>
                    <th>처리목적</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>회원가입
                        및 관리
                    </th>
                    <td>
                        - 회원 가입의사 확인, 가입 및 가입횟수 제한<br/>
                        - 회원제 서비스 제공에 따른 본인식별·인증<br/>
                        - 서비스 부정 이용 방지<br/>
                        - 만 14세 미만 아동의 개인정보 처리시 법정대리인의 동의여부 확인<br/>
                        - 고충처리 (고객상담, 고객불만 접수 및 처리,
                        분쟁조정을 위한 기록보전)<br/>
                        - 각종 고지·통지<br/>
                    </td>
                </tr>
                <tr>
                    <th>서비스 제공</th>
                    <td>
                        - 결제 서비스
                        <br/>- 이벤트/경품당첨 결과안내 및 상품배송
                    </td>
                </tr>
                <tr>
                    <th>마케팅 광고</th>
                    <td>
                        - 각종 이벤트 정보 동의 안내 (선택)<br/>
                        - 회원 정보 및 구매 데이터에 대한 분석을 통한 신규 서비스 개발 및 품질 개선<br/>
                        - 마케팅, 멤버십 정책 수립 및 프로그램 운영<br/>
                    </td>
                </tr>
                </tbody>
            </table>
        </article>
        <article>
            <h5>2. 개인정보의 수집 항목 및 수집 방법</h5>
            <table className="polyTb">
                <caption>개인정보의 수집 항목 및 수집 방법</caption>
                <colgroup>
                    <col/>
                    <col/>
                    <col/>
                </colgroup>
                <thead>
                <tr>
                    <th>구분</th>
                    <th>수집 및 이용 항목</th>
                    <th>수집 및 이용 목적</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>무료 회원
                        (홈페이지 회원,
                        모바일 앱 회원)
                    </th>
                    <td>
                        -(필수) : ID, 비밀번호, 성명, 생년월일, 성별, 주소, 휴대전화번호, 이메일주소, 신용평가기관 본인 인증 값(CI)
                    </td>
                    <td rowSpan={5}>회원 가입,
                        본인 확인,
                        회원제 서비스의
                        이용</td>
                </tr>
                <tr>
                    <th>14세 미만 회원</th>
                    <td style={{borderRight: "1px solid #e4e5e6" }}>
                        [14세 미만 회원]
                        성명, ID, 비밀번호, 생년월일, 성별, 주소,
                        휴대전화번호, 이메일주소
                        <br/>
                        [법정대리인 본인 확인 정보]
                        성명, 생년월일, 성별, 휴대전화번호,
                        신용평가기관 본인 인증 값(CI)

                    </td>
                </tr>
                <tr>
                    <th>유료 회원
                        (T-클럽  회원)
                    </th>
                    <td style={{borderRight: "1px solid #e4e5e6" }}>
                        -(필수) : ID, 비밀번호, 성명, 생년월일, 성별, 주소, 휴대전화번호, 이메일주소, 신용평가기관 본인 인증 값(CI)
                    </td>
                </tr>
                <tr>
                    <th>
                        유료 회원
                        (어린이 회원)
                    </th>
                    <td style={{borderRight: "1px solid #e4e5e6" }}>
                        -(필수) : ID, 비밀번호, 성명, 생년월일, 성별, 주소, 휴대전화번호, 이메일주소, 신용평가기관 본인 인증 값(CI)
                        <br />
                        -(선택) : 대상 어린이(자녀)의 성명, 성별, 연령
                    </td>
                </tr>
                <tr>
                    <th>티켓예매 회원</th>
                    <td style={{borderRight: "1px solid #e4e5e6" }}>-(필수) : 멤버십 번호, 이름, 휴대전화번호</td>
                </tr>
                <tr>
                    <th>회원 공통</th>
                    <td>
                        - 자동수집정보(필수) : 서비스 이용기록, 접속로그, 쿠키, 접속 IP
                        정보, 방문일시, 불량이용기록
                    </td>
                    <td>서비스 방문 및 이용기록의 분석, 부정이용 방지
                        등을 위한
                        기록 관리
                    </td>
                </tr>
                </tbody>
            </table>
            <p className="txtAdd">
                회사는 개인정보를 사이트 회원가입 절차, 이벤트나 경품 행사 등을 통해
                수집하며 휴대전화 및 유-무선 인터넷 서비스를 사용할 때 생성정보 수집
                도구를 통한 방법(쿠키) 등으로도 개인정보를 수집합니다.
            </p>
        </article>
        <article>
            <h5>3. 개인정보의 제3자 제공</h5>
            <p>
                회사는 고객의 개인정보를 &quot;2. 개인정보의 수집 항목 및
                수집 방법&quot;에서 고지한 범위 내에서 사용하며, 고객의 사전
                동의 없이 개인정보를 제3자에게 제공하지 않습니다.
                <br/>
                단, 다음과 같은 경우 개인정보를 외부에 제공하며, 본
                정보는 기술적 보호조치를 적용하여 안전하게 전달됩니다.
            </p>
            <ul className="txtBar">
                <li>
                    고객에게 개인정보 제3자 제공에 대해 별도 동의를 받은
                    경우
                </li>
                <li>
                    법령에 의거 개인정보 제출 의무를 준수하기 위하여
                    불가피한 경우 (압수수색영장 또는 기관장의 직인이 날인된
                    공식적인 서면 요청 등 적법한 절차에 따라 제공)
                </li>
                <li>
                    법령에 따라 고객의 동의 없이 당초 수집 목적과 합리적으로
                    관련된 범위에서 예측 가능성 및 고객 불이익 발생여부,
                    보호조치 여부 등을 고려하여 개인정보 제공 가능
                </li>
            </ul>
        </article>
        <article>
            <h5>4. 개인정보의 처리위탁</h5>
            <p>
                회사는 더 나은 서비스 제공을 위해 업무 중 일부를 외부
                업체에 위탁하고 있습니다.
                회사는 위탁받은 업체(수탁업체)가 개인정보를 안전하게
                처리하도록 필요한 사항을 계약서 등 문서에 명시하고, 이를
                준수하도록 관리·감독합니다.
                수탁업체는 서비스 변경 및 계약기간에 따라 변경될 수
                있으며, 이 경우 본 개인정보 처리방침을 통하여 공개합니다.
            </p>
            <table className="polyTb">
                <caption></caption>
                <colgroup>
                    <col/>
                    <col/>
                    <col/>
                </colgroup>
                <thead>
                <tr>
                    <th>수탁업체</th>
                    <th>위탁업무 내용</th>
                    <th>보유 및 이용기간</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>엔에이치엔티켓링크 ㈜</th>
                    <td>티켓 예매, 유료 회원 결제</td>
                    <td rowSpan="6">회원탈퇴 시 또는 위탁계약 종료시</td>
                </tr>
                <tr>
                    <th>더스포츠 커뮤니케이션</th>
                    <td style={{borderRight: "1px solid #e4e5e6" }}>
                        KIA 타이거즈 홈페이지(웹사이트),
                        어플리케이션, CRM시스템,
                        쇼핑몰의 개발 및 유지관리
                    </td>
                </tr>
                <tr>
                    <th>현대오토에버㈜</th>
                    <td style={{borderRight: "1px solid #e4e5e6" }}>개인정보 처리시스템 인프라 운영관리</td>
                </tr>
                <tr>
                    <th>NICE평가정보㈜</th>
                    <td style={{borderRight: "1px solid #e4e5e6" }}>
                        회원가입 시 본인인증, 만 14세 미만 회원가입 시 법정대리인 본인인증
                    </td>
                </tr>
                <tr>
                    <th>굿즈연구소</th>
                    <td style={{borderRight: "1px solid #e4e5e6" }}>
                        상품 발송 등 물류 업무
                    </td>
                </tr>
                <tr>
                    <th>NICE페이먼츠(주)</th>
                    <td style={{borderRight: "1px solid #e4e5e6" }}>
                        상품 결제 서비스 운영 및 결제 처리,
                        결제 도용 방지
                    </td>
                </tr>
                </tbody>
            </table>
        </article>
        <article>
            <h5>5. 개인정보의 처리 및 보유 기간</h5>
            <p>
                회사는 원칙적으로 계약해지 또는 회원탈퇴(동의 철회) 등 개인정보 수집 및 이용목적이
                달성된 후에는 해당 정보를 지체 없이 파기합니다.
            </p>
            <ul className="txtBar">
                <p>
                    단, 다음과 같은 경우 해당 기간 동안 개인정보를 보관하며,
                    본 정보를 다른 목적으로 이용하지 않습니다.
                </p>
                <li>
                    고객에게 개인정보 보관기간에 대해 별도 동의를 받거나
                    법령에서 일정기간 보관 의무를 부과한 경우 해당 기간 동안
                    개인정보를 보관합니다.
                </li>
                <li>
                    계약금 및 이용요금 정산, 소송이나 분쟁해결 등을 위해
                    보관할 필요가 있는 경우 해당 목적이 달성될 때까지
                    개인정보를 보관합니다.
                </li>
                <li>
                    계약이 해지된 고객의 개인정보는 계약금 정산,
                    분쟁해결을 위해 계약 해지 후 6개월간 보관합니다.
                </li>
                <li>
                    만 14세 미만 아동의 법정대리인 정보는 해당 아동이 만
                    14세가 될 때까지 보관합니다.
                </li>
                <li>
                    개인정보 보호를 위해 『KIA 타이거즈 서비스 이용약관』 제
                    27 조, 28 조에 따라 1 년 이상 로그인하지 않은 고객은 자동으로
                    회원탈퇴 처리합니다.
                </li>
                <li>
                    자동 탈퇴되기 30 일 전에 이메일 또는 휴대전화 문자메시지,
                    카카오톡 알림톡으로 개별 안내하며(여러 수단 중 1 건 또는 중복
                    안내될 수 있으나, 1 건의 안내라도 정상 안내로 간주합니다.),
                    탈퇴 예정일까지 로그인하지 않을 경우, 고객 계정 및
                    개인정보들은 모두 삭제됩니다.
                </li>
            </ul>
            <p className="txtBold">&lt;회사 내부 방침에 의한 정보 보관&gt;</p>
            <p>
                부정이용기록(부정가입, 징계기록 등의 비정상적 서비스
                이용기록)으로 인한 정보 보존 필요가 있는 경우
            </p>
            <ul className="txtBar">
                <li>
                    보관 항목 : 신용평가기관 본인인증값(CI), 만 14세 미만 회원의 경우 법정대리인 CI
                </li>
                <li>
                    보관 목적 : 부정 가입 및 이용 방지
                </li>
                <li>
                    보관 기간 : 10년
                </li>
            </ul>
            <p>
                ※ ‘부정이용기록’이란 부정 가입 및 운영원칙에 위배되는 게시글 작성
                등으로 인해 회사로부터 이용제한 등을 당한 기록입니다.
            </p>
            <p className="txtBold">&lt;관련 법령에 의한 정보 보관&gt;</p>
            <ul className="txtBar">
                <li>
                    (통신비밀보호법) 로그인 기록 : 3개월
                </li>
                <li>
                    (상법) 회사의 상업장부와 영업에 관한 중요서류 : 10년
                </li>
                <li>
                    (상법) 전표 또는 이와 유사한 서류 : 5년
                </li>
                <li>
                    (국세기본법, 법인세법) 거래내역 및 증빙서류와 관련된
                    내용 : 그 거래 사실이 속하는 과세 기간에 대한 해당 국세의
                    법정 신고 기한이 지난 날부터 5년
                </li>
                <li>
                    (전자상거래 등에서의 소비자보호에 관한 법률) 계약 또는
                    청약철회 등에 관련 기록 : 5년
                </li>
                <li>
                    (전자상거래 등에서의 소비자보호에 관한 법률) 대금결제
                    및 재화 등의 공급에 관한 기록 : 5년
                </li>
                <li>
                    (전자상거래 등에서의 소비자보호에 관한 법률) 소비자의
                    불만 또는 분쟁처리에 관한 기록 : 3년
                </li>
                <li>
                    (전자상거래 등에서의 소비자보호에 관한 법률)
                    표시/광고에 관한 기록 : 6개월
                </li>
            </ul>
        </article>
        <article>
            <h5>6. 개인정보의 파기절차 및 방법</h5>
            <p>
                회사는 원칙적으로 개인정보 보유기간의 경과, 처리목적
                달성 등 개인정보가 불필요하게 되었을 때에는 해당 정보를
                지체 없이 파기합니다.
                <br/>
                정보주체로부터 동의받은 개인정보 보유기간이 경과하거나
                처리목적이 달성되었음에도 불구하고 다른 법령에 따라
                개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를
                별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여
                보존합니다.
            </p>
            <p className="txtBold">&lt;파기절차&gt;</p>
            <ul className="txtBar">
                <li>
                    고객이 회원가입 등을 위해 입력한 정보는 목적이
                    달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의
                    서류함) 내부방침 및 기타 관련 법령에 의한 정보보호
                    사유에 따라(“5. 개인정보의 처리 및 보유 기간” 참조)
                    일정기간 저장된 후 파기됩니다.
                </li>
            </ul>
            <p className="txtBold">&lt;파기방법&gt;</p>
            <ul className="txtBar">
                <li>
                    종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                    파기합니다.
                </li>
                <li>
                    전자적 파일형태로 저장된 개인정보는 기록을 복구 및 재생할 수 없는 기술적
                    방법을 사용하여 삭제합니다.
                </li>
            </ul>
        </article>

        <article>
            <h5>7. 정보주체 및 법정대리인의 권리•의무와 그 행사방법</h5>
            <p>
                고객은 언제든지 본인 개인정보의 열람, 정정, 삭제,
                처리정지 등을 요청할 수 있으며, 법률에 특별한 규정이 없는
                경우 회사는 해당 요청을 처리합니다.
            </p>
            <ul className="txtBar">
                <li>
                    회사는 고객의 권리를 행사하는 자가 본인 또는 적법한
                    대리인(또는 보호의무자)인지 여부를 신분증 또는
                    위임장 등을 통해 확인할 수 있습니다.
                </li>
                <li>
                    만 14세 미만 아동의 경우, 법정대리인이 정보주체
                    권리 행사의 권한을 가집니다.
                </li>
                <li>
                    고객이 개인정보 정정 요구 시 회사는 오류가 정정될
                    때까지 개인정보를 이용ㆍ제공하지 않으며, 이미
                    제공된 경우 외부업체에 해당 사실을 통지하여 정정
                    처리합니다.
                </li>
                <li>
                    서비스 제공에 반드시 필요한 개인정보의 수집ㆍ이용
                    동의 철회 시 회원 탈퇴 또는 서비스 해지가
                    불가피하며, 이로 인해 서비스 이용이 제한될 수
                    있습니다.
                </li>
            </ul>
            <br/>
            <p>
                고객은 대상 서비스의 성격에 따라 다음의 방법 중 선택하여
                위와 같은 권리를 행사할 수 있습니다.
            </p>
            <ul className="txtBar">
                <li>
                    회원 개인정보 관리 메뉴의 ‘개인정보 변경’ 또는
                    ‘회원정보 수정’ 등 에서 직접 정보 확인 및 수정을 하실
                    수 있으며, 회원탈퇴(동의철회)를 위해서는
                    ‘회원탈퇴’를 클릭하여 본인 확인 절차를 거친 후 직접
                    탈퇴가 가능합니다.
                </li>
                <li>
                    본 개인정보 처리방침에 공지된 전화, 이메일을 통해
                    개인정보 업무 담당자에게 처리 요청을 하실 수
                    있습니다.
                </li>
            </ul>
        </article>

        <article>
            <h5>8. 개인정보 자동 수집장치의 설치/운영 및 거부에 관한 사항</h5>
            <p>
                회사는 효율적인 서비스 제공을 위해 &#39;쿠키(cookie)&#39; 등을
                설치·운용할 수 있으며, 고객은 웹브라우저 설정 등을 통해
                언제든지 쿠키 설치 여부를 선택할 수 있습니다. 회사는
                다음과 같은 목적을 위해 쿠키를 사용합니다.
            </p>
            <p>
                ※ 쿠키란 웹사이트에서 고객의 웹브라우저를 통해 고객의
                PC에 저장하는 아주 작은 크기의 텍스트 파일을 의미합니다.
            </p>
            <p className="txtBold">&lt;쿠키 등 사용 목적&gt;</p>
            <ul className="txtBar">
                <li>
                    회사는 웹사이트 이용현황 및 고객의 관심분야 분석,
                    각종 이벤트 참여 여부 파악 등을 통한 타겟 마케팅 및
                    개인 맞춤 서비스 제공을 위해 쿠키 등을 사용합니다.
                </li>
                <li>
                    고객은 웹브라우저의 옵션을 설정함으로써 모든
                    쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을
                    거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
                    있습니다.
                </li>
                <li></li>
            </ul>
            <p className="txtBold">&lt;쿠키 설정 거부 방법&gt;</p>
            <ul className="txtBar">
                <li>
                    예 : 쿠키 설정을 거부하는 방법으로는 정보주체가 사용하는 웹 브라우저의
                    옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다
                    확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
                </li>
                <li>
                    설정방법 (인터넷 익스플로어의 경우) : “웹 브라우저
                    상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보 &gt; 고급 &gt;
                    설정방법”에서 쿠키 사용 여부를 선택할 수 있습니다.
                </li>
                <li>
                    단, 귀하께서 쿠키 저장을 거부하는 경우 로그인이
                    필요한 일부 서비스 또는 개인 맞춤 서비스 등
                    회사에서 제공하는 일부 기능의 이용에 어려움이 있을
                    수 있습니다.
                </li>
            </ul>
        </article>
        <article>
            <h5>9. 개인정보의 안전성 확보조치</h5>
            <p>
                회사는 고객의 개인정보를 안전하게 관리함에 있어 개인정보가 분실,
                도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은
                기술적, 관리적, 물리적 대책을 마련하고 있습니다.
            </p>
            <p className="txtBold">&lt;기술적 대책&gt;</p>
            <ul className="txtBar">
                <li>
                    개인정보의 안전한 저장 및 전송 등을 위하여 법령이 정한 기준을
                    준수하고 있습니다.
                </li>
                <li>
                    회사는 백신 프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를
                    방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로
                    업데이트 되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시
                    이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
                </li>
            </ul>
            <p className="txtBold">&lt;관리적 대책&gt;</p>
            <ul className="txtBar">
                <li>
                    회사는 정보주체를 직접 상대로 하여 영업 및 마케팅 업무를 수행하는
                    자, 개인정보관리업무를 수행 하는 자, 기타 업무상 개인정보의 취급이
                    불가피한자 등으로 개인정보에 대한 접근권한을 제한하고 있습니다.
                </li>
                <li>
                    회사는 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여
                    아래와 같이 개인정보보호책임자를 지정하고 있습니다.
                </li>
            </ul>
            <p className="txtBold">&lt;물리적 대책&gt;</p>
            <ul className="txtBar">
                <li>서버실, 자료보관실 등의 접근을 통제하고 있습니다.</li>
            </ul>
        </article>
        <article>
            <h5>10. 개인정보 보호책임자 및 담당자 안내</h5>
            <p>
                회사는 고객의 개인정보를 보호하고 개인정보와 관련된 문의사항,
                불만처리를 위해 다음과 같은 책임자를 지정하고 있습니다. 회사의
                서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 아래의
                개인정보 보호책임자 혹은 개인정보 보호담당자로 문의하실 수 있습니다.
            </p>
            <div className="polyBox2 clear">
                <div>
                    <p className="txtBold">개인정보 보호책임자</p>
                    <p>
                        - 성명 및 직위 : 설진규 상무 (지원실장)
                        <br/>
                        - 연락처 : 070-7686-8037
                        <br/>- 이메일 : kiatigers@kiatigers.co.kr
                    </p>
                </div>
                <div>
                    <p className="txtBold">개인정보 보호담당자</p>
                    <p>
                        - 성명 및 직위 : 박청아 프로
                        <br/>
                        - 연락처 : 070-7686-8037
                        <br/>- 이메일 : pca0218@kiatigers.co.kr
                    </p>
                </div>
            </div>
            <p className="txtBold">
                &lt;기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
                기관에 문의하시기 바랍니다.&gt;
            </p>
            <ul className="txtBar">
                <li>
                    개인정보침해 신고센터 : 국번없이 118,
                    privacy.kisa.or.kr
                </li>
                <li>개인정보 분쟁조정위원회 : 1833-6972, kopico.go.kr</li>
                <li>
                    대검찰청 사이버수사과 : 국번없이 1301 또는 02-
                    3480-3570, www.spo.go.kr
                </li>
                <li>
                    경찰청 사이버범죄 신고시스템 : 국번없이 182,
                    ecrm.police.go.kr
                </li>
            </ul>
        </article>
        <article>
            <h5>11. 적용범위</h5>
            <p>
                회사에 링크되어 있는 다른 회사의 웹사이트들이 개인정보를 수집하는
                경우, 고객 동의 하에 개인정보가 제공된 이 후에는 본 "개인정보
                처리방침"이 적용되지 않음을 알려드립니다.
            </p>
        </article>
        <article>
            <h5>12. 개인정보 처리방침의 변경에 관한 사항</h5>
            <p>
                본 “개인정보 처리방침”은 관련 법규 및 지침의 변경 또는
                내부 운영규정에 따라 개정될 수 있으며, “개인정보
                처리방침”을 개정하는 경우 개정된 사항을 회사 홈페이지
                (https://tigers.co.kr) 공지사항을 통하여 공지합니다.
            </p>
            <br/>
            <ul className="txtBar">
                <p>① 본 방침은 2025년 01월 20일부터 적용됩니다.</p>
                <p>② 이전의 개인정보 처리방침은 아래에서 확인할 수 있습니다.</p>
                <ul className="txtBar">
                    <li>2013.03.30 ~ 2017.03.30 적용 <button onClick={() => setVersion("Old170330")}>보기</button></li>
                    <li>2017.03.31 ~ 2017.05.16 적용 <button onClick={() => setVersion("Old170516")}>보기</button></li>
                    <li>2017.05.17 ~ 2018.07.12 적용 <button onClick={() => setVersion("Old180713")}>보기</button></li>
                    <li>2018.07.13 ~ 2020.02.10 적용 <button onClick={() => setVersion("Old200210")}>보기</button></li>
                    <li>2020.02.11 ~ 2020.03.24 적용 <button onClick={() => setVersion("Old200324")}>보기</button></li>
                    <li>2020.03.25 ~ 2021.03.16 적용 <button onClick={() => setVersion("Old200325")}>보기</button></li>
                    <li>2021.03.17 ~ 2023.02.27 적용 <button onClick={() => setVersion("Old230228")}>보기</button></li>
                    <li>2023.02.28 ~ 2025.01.19 적용 <button onClick={() => setVersion("Old250119")}>보기</button></li>
                    <li>2025.01.20 ~</li>
                </ul>
            </ul>
            <br className="pc"/>
        </article>
    </div>;
}

export default Latest;
