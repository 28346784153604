import React from "react";
import { Link } from "react-router-dom";

const ContComponent = (props) => (
  <div className="mb_cont login">
    <div className="mb_contIn">
      <form action="/" method="post">
        <fieldset>
          <legend>로그인 양식</legend>
          <div className="idPw">
            <h3>
              <img src="/img/sub/login_ci.png" alt="KIA TIGERS" />
            </h3>
            <p>
              <label htmlFor="userID">
                <img src="/img/sub/login_ico_id.png" alt="아이디" />
              </label>
              <input
                type="text"
                id="userID"
                placeholder="아이디 입력"
                value={props.idObj.value}
                onChange={(evt) => props.onChangeInput(evt, "idObj", "value")}
                onFocus={(evt) => props.onFocusInput(evt, "id")}
                onKeyPress={(evt) => props.onEnterKey(evt)}
              />
            </p>
            <p>
              <label htmlFor="userPw">
                <img src="/img/sub/login_ico_pw.png" alt="비밀번호" />
              </label>
              <input
                type="password"
                id="userPw"
                placeholder="비밀번호 입력"
                value={props.pwObj.value}
                onChange={(evt) => props.onChangeInput(evt, "pwObj", "value")}
                onFocus={(evt) => props.onFocusInput(evt, "pw")}
                onKeyPress={(evt) => props.onEnterKey(evt)}
              />
            </p>
            <div className="login_desc">
              <strong>ㆍ 아이디 6자리 이상(영문, 숫자)</strong>
              <br />
              <strong>ㆍ 비밀번호 8자리 이상(영문, 숫자, 특수문자)</strong>
            </div>
            <button
              type="button"
              className="btnLogin"
              onClick={props.onClickBtnLogin}
            >
              로그인
            </button>
            {/* <button type='button' className="btnNaver" onClick={props.onClickBtnNaverLogin}>네이버 아이디로 로그인</button> */}
          </div>
          <div className="fndJoin">
            <div>
              <p>아이디나 비밀번호가 기억나지 않으세요?</p>
              <ul>
                <li>
                  {/* <Link href="#" to={"/login/find-id"}>
                    아이디 찾기
                  </Link> */}

                  {/* 통합로그인 사이트이동 */}
                  <Link href="#" onClick={props.onClickBtnFindIdUnifiedId}>
                      아이디 찾기
                  </Link>
                </li>
                <li>
                  {/* <Link href="#" to={"/login/find-pw"}>
                    비밀번호 찾기
                  </Link> */}

                  {/* 통합로그인 사이트이동 */}
                  <Link href="#" onClick={props.onClickBtnFindIdUnifiedPw}>
                    비밀번호 찾기
                  </Link>
                </li>
              </ul>
              <p>아직 기아타이거즈의 회원이 아니신가요?</p>
              <ul className="btnJoin">
                <li>
                  <Link href="#" to={"/signup"}>
                    회원가입하기
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </fieldset>
      </form>
      <div className={(props.isShowPopup ? "on" : "") + " pop"}>
        <p className="popBg"></p>
        <div className="popIn">
          <p className="popX">
            <img
              onClick={props.onClickBtnPopupCancel}
              src="/img/sub/pop_close.png"
              alt="팝업닫기 버튼"
            />
          </p>
          <div>
            <p>
              KIA TIGERS 서비스를 이용하시려면 <br />
              본인인증이 추가로 필요합니다.
              <br />내 정보 관리에서 본인인증을 완료해주세요.
            </p>
            <p className="bold">내 정보 관리 페이지로 이동하시겠습니까?</p>
            <div className="popBtnWrap">
              <button
                onClick={props.onClickBtnPopupCancel}
                type="button"
                className="gray"
              >
                취소
              </button>
              <button onClick={props.onClickBtnPopupConfirm} type="button">
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- //mb_contIn --> */}
    </div>
    {/* <!-- //mb_cont --> */}
  </div>
);

export default ContComponent;
