import React from "react";

const Latest = ({ setVersion }) => {
	return (
		<div>
			<div style={{clear: "both"}}>
				<p className="Header" style={{fontSize: "10pt"}}>

				</p>
				<p className="Header">
					<span>&#xa0;</span>
				</p>
			</div>

			<p style={{fontSize: "16pt"}}>
				<strong>개인정보취급방침 / </strong>
				<strong>
          <span style={{fontSize: "12pt", color: "#808080"}}>
            2017년 03월 31일
          </span>
				</strong>
			</p>
			<button onClick={() => {
				setVersion("Latest")
			}}>현재 개인정보 처리방침 보기
			</button>
			<p>
				<strong>&#xa0;</strong>
			</p>
			<p style={{textIndent: "11pt"}}>
				<strong>
					주식회사 KIA타이거즈(이하 '회사'라고함)는 개인정보보호법,
					정보통신망이용촉진 및 정보보호에 관한 법률 등 관련 법령상의
					개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보취급방침 등을
					정하여 고객, 임직원, 홈페이지 이용자 등 정보주체의 권익 보호에 최선을
					다하고 있습니다.
				</strong>
				<br/>
				<strong>
					{" "}
					회사는 개인정보취급방침 등을 통하여 정보주체가 제공하는 개인정보가
					어떠한 용도와 방식으로 처리되고 있으며, 개인정보보호를 위해 어떠한
					조치가 취해지고 있는지 알려드립니다.
				</strong>
			</p>
			<p>
				<strong>&#xa0;</strong>
			</p>
			<p className="ListParagraph" style={{marginLeft: "28.35pt"}}>
				<strong>1. 총칙</strong>
				<br/>
				<strong>2. 개인정보의 수집 항목 및 목적</strong>
				<br/>
				<strong>3. 개인정보의 보유 및 이용기간</strong>
				<br/>
				<strong>4. 개인정보의 파기절차 및 방법</strong>
				<br/>
				<strong>5. 개인정보의 제3자 제공</strong>
				<br/>
				<strong>6. 개인정보취급의 위탁</strong>
				<br/>
				<strong>7. 정보주체의 권리의무 및 그 행사방법</strong>
				<br/>
				<strong>8. 개인정보의 열람 및 정정</strong>
				<br/>
				<strong>9. 동의철회(회원탈퇴)</strong>
				<br/>
				<strong>10. 개인정보의 기술적/관리적 보호대책</strong>
				<br/>
				<strong>11. 기타사항</strong>
				<br/>
				<strong>&#xa0;</strong>
			</p>

			<p style={{fontSize: "14pt"}}>
				<strong>1. 총칙</strong>
			</p>
			<p>
				<strong>
					‘개인정보’란 살아있는 개인에 관한 정보로서 성명, 휴대전화번호 및
					이메일 등을 통하여 개인을 알아볼 수 있는 정보 (해당 정보만으로는 특정
					개인을 알아볼 수 없더라도 다른 정보와 쉽게 결합하여 알아볼 수 있는 것을
					포함한다)를 말합니다.
				</strong>
			</p>
			<p>
				<strong>
					‘정보주체’란 처리되는 정보에 의하여 알아볼 수 있는 사람으로서 그
					정보의 주체가 되는 사람을 말합니다.
				</strong>
			</p>
			<p>
				<strong>
					회사는 개인정보취급방침을 홈페이지(
					<a href="http://www.tigers.co.kr" style={{textDecoration: "none"}}>
            <span className="Hyperlink" style={{fontWeight: "bold"}}>
              www.tigers.co.kr
            </span>
					</a>
					)에 공개함으로써 언제나 쉽게 확인할 수 있도록 조치하고 있습니다.
				</strong>
			</p>

			<p style={{fontSize: "14pt"}}>
				<strong>&#xa0;</strong>
			</p>
			<p style={{fontSize: "14pt"}}>
				<strong>2. 개인정보의 수집 항목 및 목적</strong>
			</p>
			<p>
				<strong>
					회사는 법령의 규정 또는 정보주체의 동의 없이는 정보주체의 사생활을 현저히
					침해할 우려가 있는 민감정보, 개인을 고유하게 구별하기 위하여 부여된
					고유식별정보를 취급하지 않습니다.
				</strong>
			</p>
			<p>
				<strong>
					<em>가. 개인정보의 수집 항목</em>
				</strong>
			</p>
			<p>
				<strong>
					회사는 회원가입, 원활한 고객상담문의, 티켓예매, 다양한 서비스 제공을 위해
					회원가입 시 아래와 같은 개인정보를 수집하고 있습니다.
				</strong>
			</p>
			<ol className="awlist1" style={{margin: 0, paddingLeft: 0}}>
				<li
					className="ListParagraph"
					style={{
						marginLeft: "41.5pt",
						marginBottom: 0,
						textIndent: "-19.5pt",
						fontWeight: "bold",
					}}
				>
          <span
			  style={{
				  width: "5.25pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;{" "}
          </span>
					필수항목
					<br/>
					- 아이디, 비밀번호, 이름, 생년월일, 성별, 가입인증 휴대폰번호, 만14세 미만
					아동의 경우 법정대리인 정보 (법정대리인의 이름, ID, 휴대폰 번호가
					수집되며, 아동이 성년이 될 때까지 보관됩니다.)
				</li>
				<li
					className="ListParagraph"
					style={{
						marginLeft: "41.5pt",
						marginBottom: 0,
						textIndent: "-19.5pt",
						fontWeight: "bold",
					}}
				>
          <span
			  style={{
				  width: "5.25pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;{" "}
          </span>
					서비스이용 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
					<br/>
					- 서비스이용기록, 접속로그, 쿠키, 접속 IP 정보, 방문일시, 불량이용기록
				</li>
				<li
					className="ListParagraph"
					style={{
						marginLeft: "41.5pt",
						marginBottom: 0,
						textIndent: "-19.5pt",
						fontWeight: "bold",
					}}
				>
          <span
			  style={{
				  width: "5.25pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;{" "}
          </span>
					회원가입 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.(온라인유료회원)
					<br/>
					- 신용카드 결제시 : 카드사명, 유효기간, 카드번호 등
				</li>
			</ol>
			<p
				className="ListParagraph"
				style={{marginLeft: "41.5pt", marginBottom: 0}}
			>
				<strong>- 휴대전화 결제시 : 이동전화번호, 통신사, 결제승인번호등</strong>
			</p>
			<ol start="4" className="awlist2" style={{margin: 0, paddingLeft: 0}}>
				<li
					className="ListParagraph"
					style={{marginLeft: "41.5pt", textIndent: "-19.5pt", fontWeight: "bold"}}
				>
          <span
			  style={{
				  width: "5.25pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;{" "}
          </span>
					개인정보수집방법 : 홈페이지(회원가입, 배송요청 등) 회원가입양식
				</li>
			</ol>
			<p>
				<strong>
					<em>나. 개인정보의 수집 및 이용목적</em>
				</strong>
			</p>
			<p>
				<strong>회사에서 수집하는 개인정보는 다음에 명시한 목적으로 이용됩니다.</strong>
			</p>
			<ul className="awlist3" style={{margin: 0, paddingLeft: 0}}>
				<li
					className="ListParagraph"
					style={{
						marginLeft: "44pt",
						marginBottom: 0,
						textIndent: "-22pt",
					}}
				>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>
						컨텐츠 제공, 회원가입 시 요금결제, 물품배송, 금융거래본인인증 및 금융서비스
					</strong>
				</li>
				<li
					className="ListParagraph"
					style={{
						marginLeft: "44pt",
						marginBottom: 0,
						textIndent: "-22pt",
					}}
				>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>
						회원제서비스이용에 따른 본인확인, 회원제의 부정이용방지와 비인가사용방지,
						분쟁조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항전달
					</strong>
				</li>
			</ul>
			<ul className="awlist4" style={{margin: 0, paddingLeft: 0}}>
				<li
					className="ListParagraph"
					style={{marginLeft: "44pt", textIndent: "-22pt"}}
				>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>
						인구통계학적분석(이용자의연령별, 성별, 지역별, 접속횟수별 등 통계분석),
						신규서비스개발 및 마케팅/광고에의 활용, 서비스개편 및 확대시 정책에 반영,
						개별회원에 대한 개인맞춤서비스, 기타 새로운 서비스, 신상품이나 이벤트정보안내
					</strong>
				</li>
			</ul>
			<p>
				<strong>&#xa0;</strong>
			</p>

			<p style={{fontSize: "14pt"}}>
				<strong>3. 개인정보의 보유 및 이용기간</strong>
			</p>
			<p>
				<strong>
					이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이
					파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
				</strong>
			</p>
			<p style={{marginLeft: "17.85pt"}}>
				<strong>
					<em>&lt;회사 내부 방침에 의한 정보보유 사유&gt;</em>
				</strong>
			</p>
			<p>
				<strong>- 부정이용기록(부정가입, 징계기록 등의 비정상적 서비스 이용기록)</strong>
			</p>
			<ul style={{margin: 0, paddingLeft: 0}}>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>
						보존 항목 : 가입인증 휴대폰 번호, 만 14세 미만 회원의 경우 법정대리인 ID
					</strong>
				</li>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>보존 이유 : 부정 가입 및 이용</strong>
				</li>
				<li
					style={{
						marginLeft: "27.6pt",
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>보존 기간 : 6개월</strong>
				</li>
			</ul>
			<p>
				<strong>
					※ ‘부정이용기록’이란 부정 가입 및 운영원칙에 위배되는 게시글 작성 등으로 인해
					회사로부터 이용제한 등을 당한 기록입니다.
				</strong>
			</p>
			<p style={{marginLeft: "18pt"}}>
				<strong>
					<em>&lt;관련 법령에 의한 정보보유 사유&gt;</em>
				</strong>
			</p>
			<p>
				<strong>
					상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여
					보존할 필요가 있는 경우, 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를
					보관합니다.
				</strong>
				<br/>
				<strong>
					이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와
					같습니다.
				</strong>
			</p>
			<p>
				<strong>- 계약 또는 청약철회 등에 관한 기록</strong>
			</p>
			<ul style={{margin: 0, paddingLeft: 0}}>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</strong>
				</li>
				<li
					style={{
						marginLeft: "27.6pt",
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>보존 기간 : 5년</strong>
				</li>
			</ul>
			<p>
				<strong>- 대금결제 및 재화 등의 공급에 관한 기록</strong>
			</p>
			<ul style={{margin: 0, paddingLeft: 0}}>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</strong>
				</li>
				<li
					style={{
						marginLeft: "27.6pt",
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>보존 기간 : 5년</strong>
				</li>
			</ul>
			<p>
				<strong>- 전자금융 거래에 관한 기록 보존</strong>
			</p>
			<ul style={{margin: 0, paddingLeft: 0}}>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>보존 이유 : 전자금융거래법</strong>
				</li>
				<li
					style={{
						marginLeft: "27.6pt",
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>보존 기간 : 5년</strong>
				</li>
			</ul>
			<p>
				<strong>- 소비자의 불만 또는 분쟁처리에 관한 기록</strong>
			</p>
			<ul style={{margin: 0, paddingLeft: 0}}>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</strong>
				</li>
				<li
					style={{
						marginLeft: "27.6pt",
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>보존 기간 : 3년</strong>
				</li>
			</ul>
			<p>
				<strong>- 웹사이트 방문기록 보존</strong>
			</p>
			<ul style={{margin: 0, paddingLeft: 0}}>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>보존 이유 : 통신비밀보호법</strong>
				</li>
				<li
					style={{
						marginLeft: "27.6pt",
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>보존 기간 : 3개월</strong>
				</li>
			</ul>

			<p style={{fontSize: "14pt"}}>
				<strong>4. 개인정보의 파기절차 및 방법</strong>
			</p>
			<p>
				<strong>
					회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이
					파기합니다.
				</strong>
				<br/>
				<strong>파기절차 및 방법은 다음과 같습니다.</strong>
			</p>
			<p>
				<strong>
					<em>&lt;파기절차&gt;</em>
				</strong>
			</p>
			<ul style={{margin: 0, paddingLeft: 0}}>
				<li style={{marginLeft: "27.6pt", paddingLeft: "8.4pt", fontSize: "10pt"}}>
					<strong>
						이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로
						옮겨져(종이의 경우 별도의 서류함) 내부방침 및 기타 관련 법령에 의한 정보보호 사유에
						따라(보유 및 이용기간 참조) 일정기간 저장된 후 파기됩니다. 별도 DB로 옮겨진
						개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지
						않습니다.
					</strong>
				</li>
			</ul>
			<p>
				<strong>
					<em>&lt;파기방법&gt;</em>
				</strong>
			</p>
			<ul style={{margin: 0, paddingLeft: 0}}>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>
						종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
					</strong>
				</li>
				<li style={{marginLeft: "27.6pt", paddingLeft: "8.4pt", fontSize: "10pt"}}>
					<strong>
						전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
						삭제합니다.
					</strong>
				</li>
			</ul>
			<p>
				<strong>&#xa0;</strong>
			</p>
			<p style={{fontSize: "14pt"}}>
				<strong>5. 개인정보의 제3자 제공</strong>
			</p>
			<p>
				<strong>
					회사는 "2. 개인정보의 수집 항목 및 목적" 에서 고지한 목적 범위 내에서 개인정보를
					이용하며, 동 범위를 초과하여 개인정보를 이용하거나 제3자에게 제공
				</strong>
				<strong>·</strong>
				<strong>
					공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
				</strong>
			</p>
			<p>
				<strong>&#xa0;</strong>
			</p>
			<ul className="awlist5" style={{margin: 0, paddingLeft: 0}}>
				<li
					className="ListParagraph"
					style={{
						marginLeft: "44pt",
						marginBottom: 0,
						textIndent: "-22pt",
					}}
				>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>
						정보주체로부터 별도의 동의를 받는 경우는 각종 서비스이용 혹은 이벤트 응모,
						사용자리서치 등 여러 프로모션에 참여하시면서 개인정보 제 3자 제공에 대해 동의한
						경우입니다. 이 경우도 반드시 고지 및 동의 받은 목적과 기간 내에서만 이용되며, 본
						개인정보취급방침에 명기한 사항에 따라 처리됩니다.
					</strong>
				</li>
				<li
					className="ListParagraph"
					style={{
						marginLeft: "44pt",
						marginBottom: 0,
						textIndent: "-22pt",
					}}
				>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>
						법령의 규정에 의하거나, 수사목적으로 법령에 정해진 방법에 따라 수사기관의 요구가
						있는 경우
					</strong>
				</li>
				<li
					className="ListParagraph"
					style={{
						marginLeft: "44pt",
						marginBottom: 0,
						textIndent: "-22pt",
					}}
				>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>다른 법률에 특별한 규정이 있는 경우</strong>
				</li>
				<li
					className="ListParagraph"
					style={{
						marginLeft: "44pt",
						marginBottom: 0,
						textIndent: "-22pt",
					}}
				>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>
						정보주체 또는 그 법정 대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로
						사전동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체,
						재산의 이익을 위하여 필요하다고 인정되는 경우
					</strong>
				</li>
				<li
					className="ListParagraph"
					style={{marginLeft: "44pt", textIndent: "-22pt"}}
				>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>
						통계작성 및 학술 연구등의 목적에 의하여 필요한 경우로서 특정개인을 알아볼 수 없는
						형태로 개인정보를 제공하는 경우
					</strong>
				</li>
			</ul>
			<p style={{fontSize: "14pt"}}>
				<strong>&#xa0;</strong>
			</p>
			<p style={{fontSize: "14pt"}}>
				<strong>6. 개인정보취급의 위탁</strong>
			</p>
			<p>
				<strong>
					회사는 정보주체의 동의 없이 개인정보의 취급을 외부 업체에 위탁 하지 않습니다.
					회사는 아래와 같이 개인정보 취급을 위탁하고 있으며, 관련 법령에 따라 위탁계약시
					개인정보가 안전하게 관리 될 수 있도록 필요한 사항을 규정하고 있습니다.
				</strong>
			</p>

			<table
				style={{
					width: "501.7pt",
					marginBottom: 0,
					padding: 0,
					borderCollapse: "collapse",
				}}
			>
				<thead>
				<tr>
					<td
						style={{
							width: "144.18pt",
							borderRight: "0.75pt solid #e4e5e6",
							borderBottom: "0.75pt solid #c0c0c0",
							padding: "6pt 0pt",
							backgroundColor: "#ea0029",
						}}
					>
						<p style={{marginBottom: 0, textAlign: "center"}}>
							<strong>
								<span style={{color: "#ffffff"}}>수탁업체</span>
							</strong>
						</p>
					</td>
					<td
						style={{
							width: "357.15pt",
							borderBottom: "0.75pt solid #d9d9d9",
							padding: "6pt 0pt",
							backgroundColor: "#ea0029",
						}}
					>
						<p style={{marginBottom: 0, textAlign: "center"}}>
							<strong>
								<span style={{color: "#ffffff"}}>위탁업무 내용</span>
							</strong>
						</p>
					</td>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td
						style={{
							width: "144.18pt",
							borderRight: "0.75pt solid #e4e5e6",
							borderBottom: "0.75pt solid #e4e5e6",
							padding: "6pt 0pt",
							verticalAlign: "top",
							backgroundColor: "#ffffff",
						}}
					>
						<p>
							<strong>티켓링크</strong>
						</p>
					</td>
					<td
						style={{
							width: "342.15pt",
							borderTop: "0.75pt solid #d9d9d9",
							borderBottom: "0.75pt solid #e4e5e6",
							padding: "6pt 7.5pt",
							verticalAlign: "top",
						}}
					>
						<p>
							<strong>멤버십회원 예매</strong>
							<br/>
							<strong>(본인인증 및 서비스 이용에 따른 본인 확인 등)</strong>
						</p>
					</td>
				</tr>

				<tr>
					<td
						style={{
							width: "144.18pt",
							borderRight: "0.75pt solid #e4e5e6",
							borderBottom: "0.75pt solid #e4e5e6",
							padding: "6pt 0pt",
							verticalAlign: "top",
							backgroundColor: "#ffffff",
						}}
					>
						<p>
							<strong>㈜가비아씨엔에스</strong>
						</p>
					</td>
					<td
						style={{
							width: "342.15pt",
							borderBottom: "0.75pt solid #e4e5e6",
							padding: "6pt 7.5pt",
							verticalAlign: "top",
						}}
					>
						<p>
							<strong>
								홈페이지 관리, 개인정보 또는 회원 관리를 위한 시스템 구축,
							</strong>
							<br/>
							<strong>회원제 서비스 이용에 따른 본인확인, 공지사항 관리</strong>
						</p>
					</td>
				</tr>

				<tr>
					<td
						style={{
							width: "144.18pt",
							borderRight: "0.75pt solid #e4e5e6",
							borderBottom: "0.75pt solid #e4e5e6",
							padding: "6pt 0pt",
							verticalAlign: "top",
							backgroundColor: "#ffffff",
						}}
					>
						<p>
							<strong>NICE평가정보㈜</strong>
						</p>
					</td>
					<td
						rowSpan={2}
						style={{
							width: "342.15pt",
							borderBottom: "0.75pt solid #d9d9d9",
							padding: "6pt 7.5pt",
						}}
					>
						<p style={{textAlign: "justify"}}>
							<strong>본인확인</strong>
						</p>
					</td>
				</tr>
				<tr>
					<td
						style={{
							width: "144.18pt",
							borderRight: "0.75pt solid #e4e5e6",
							borderBottom: "0.75pt solid #e4e5e6",
							padding: "6pt 0pt",
							verticalAlign: "top",
							backgroundColor: "#ffffff",
						}}
					>
						<p>
							<strong>한국정보통신㈜</strong>
						</p>
					</td>
				</tr>

				<tr>
					<td
						style={{
							width: "144.18pt",
							borderRight: "0.75pt solid #e4e5e6",
							borderBottom: "0.75pt solid #e4e5e6",
							padding: "6pt 0pt",
							verticalAlign: "top",
							backgroundColor: "#ffffff",
						}}
					>
						<p>
							<strong>비젼코리아</strong>
						</p>
					</td>
					<td
						style={{
							width: "342.15pt",
							borderTop: "0.75pt solid #d9d9d9",
							borderBottom: "0.75pt solid #e4e5e6",
							padding: "6pt 7.5pt",
							verticalAlign: "top",
						}}
					>
						<p>
							<strong>
								구매 및 요금결제, 물품배송 또는 청구지 등 발송,
								<br/>
								금융거래 본인인증 및 금융서비스, 회원관리 회원제 서비스,
								<br/>
								이용에 따른 본인확인, 기타계약이행을 위한 대리점 운영
							</strong>
						</p>
					</td>
				</tr>
				</tbody>
			</table>

			<p>
				<strong>※ 개인정보의 보유 및 이용기간 : 회원탈퇴 시 또는 위탁계약 종료시</strong>
			</p>
			<p>
				<strong>&#xa0;</strong>
			</p>
			<p style={{fontSize: "14pt"}}>
				<strong>7. 정보주체의 권리의무 및 그 행사방법</strong>
			</p>
			<ul style={{margin: 0, paddingLeft: 0}}>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>
						이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만
						아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
					</strong>
				</li>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>
						이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는
						'개인정보변경'(또는 '회원정보수정' 등)을 통해서 하실 수 있으며,
						가입해지(동의철회)를 위해서는 ‘회원탈퇴’를 클릭하여 본인 확인 절차를 거치신 후
						직접 열람, 정정 또는 탈퇴가 가능합니다.
					</strong>
				</li>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>
						또한 권리 행사는 개인정보보호책임자에게 서면, 전화 또는 이메일로도 요청하실 수
						있으며, 해당 요청시 지체 없이 조치하겠습니다.
					</strong>
				</li>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>
						정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통해 권리 행사 시에는 법에서
						정한 위임장을 제출하셔야 합니다.
					</strong>
				</li>
				<li style={{marginLeft: "27.6pt", paddingLeft: "8.4pt", fontSize: "10pt"}}>
					<strong>
						아울러, 정보주체는 개인정보보호법 등 관계법령을 위반하여 회사가 처리하고 있는
						정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.
					</strong>
				</li>
			</ul>

			<p style={{fontSize: "14pt"}}>
				<strong>&#xa0;</strong>
			</p>
			<p style={{fontSize: "14pt"}}>
				<strong>8. 개인정보의 열람 및 정정</strong>
			</p>
			<ul style={{margin: 0, paddingLeft: 0}}>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>
						개인정보보호책임자에게 서면, 전화, 이메일, 모사전송(FAX) 등을 통해서 개인정보
						열람요구, 정정요구, 삭제요구, 처리정지 요구를 하실 수 있으며, 회사는 이에 대해
						지체 없이 조치하겠습니다.
					</strong>
				</li>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>
						이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지
						당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미
						제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록
						하겠습니다.
					</strong>
				</li>
			</ul>
			<p>
				<strong>&#xa0;</strong>
			</p>
			<p style={{fontSize: "14pt"}}>
				<strong>9. 동의철회(회원탈퇴)</strong>
			</p>
			<ul style={{margin: 0, paddingLeft: 0}}>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>
						이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동에
						대해 가입해지(동의철회)를 요청할 수 있습니다.
					</strong>
				</li>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>
						가입해지(동의철회)를 위해서는 ‘회원탈퇴’를 클릭하여 본인 확인 절차를 거치신 후 직접
						탈퇴가 가능합니다. 혹은 개인정보보호책임자에게 서면, 전화 또는 이메일로
						요청하시면 지체 없이 조치하겠습니다.
					</strong>
				</li>
				<li style={{marginLeft: "27.6pt", paddingLeft: "8.4pt", fontSize: "10pt"}}>
					<strong>
						회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는
						"3. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람
						또는 이용할 수 없도록 처리합니다.
					</strong>
				</li>
			</ul>
			<p>
				<strong>&#xa0;</strong>
			</p>

			<p style={{fontSize: "14pt"}}>
				<strong>10. 개인정보의 기술적/관리적 보호대책 </strong>
			</p>
			<p>
				<strong>
					회사는 이용자의 개인정보를 안전하게 관리함에 있어 개인정보가 분실, 도난, 유출,
					변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적, 관리적 대책을
					마련하고 있습니다.
				</strong>
			</p>
			<p>
				<strong>
					<em>&lt;기술적 대책&gt;</em>
				</strong>
			</p>
			<ul style={{margin: 0, paddingLeft: 0}}>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>
						개인정보의 안전한 저장 및 전송 등을 위하여 법령이 정한 기준을 준수하고 있습니다.
					</strong>
				</li>
				<li style={{marginLeft: "27.6pt", paddingLeft: "8.4pt", fontSize: "10pt"}}>
					<strong>
						회사는 백신 프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를
						취하고 있습니다. 백신프로그램은 주기적으로 업데이트 되며 갑작스런 바이러스가
						출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을
						방지하고 있습니다.
					</strong>
				</li>
			</ul>
			<p>
				<strong>
					<em>&lt;관리적 대책&gt;</em>
				</strong>
			</p>
			<ul style={{margin: 0, paddingLeft: 0}}>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>
						회사는 정보주체를 직접 상대로 하여 영업 및 마케팅 업무를 수행하는 자,
						개인정보관리업무를 수행 하는 자, 기타 업무상 개인정보의 취급이 불가피한자 등으로
						개인정보에 대한 접근권한을 제한하고 있습니다.
					</strong>
				</li>
				<li style={{marginLeft: "27.6pt", paddingLeft: "8.4pt", fontSize: "10pt"}}>
					<strong>
						회사는 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이
						개인정보보호책임자를 지정하고 있습니다.
					</strong>
				</li>
			</ul>
			<p>
				<strong>
					<em>&lt;개인정보 보호책임자&gt;</em>
				</strong>
			</p>
			<ul className="awlist6" style={{margin: 0, paddingLeft: 0}}>
				<li
					className="ListParagraph"
					style={{
						marginLeft: "44pt",
						marginBottom: 0,
						textIndent: "-22pt",
					}}
				>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>이름 : 류정철</strong>
				</li>
				<li
					className="ListParagraph"
					style={{
						marginLeft: "44pt",
						marginBottom: 0,
						textIndent: "-22pt",
					}}
				>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>전화번호 : 070-7686-8034</strong>
				</li>
				<li
					className="ListParagraph"
					style={{
						marginLeft: "44pt",
						marginBottom: 0,
						textIndent: "-22pt",
					}}
				>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>팩스번호 : 062-525-5350</strong>
				</li>
				<li
					className="ListParagraph"
					style={{
						marginLeft: "44pt",
						marginBottom: 0,
						textIndent: "-22pt",
						color: "#0563c1",
					}}
				>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong style={{color: "#000000"}}>이메일 : </strong>
					<a
						href="mailto:kiatigers@kiatigers.co.kr"
						style={{textDecoration: "none", fontWeight: "bold"}}
					>
						kiatigers@kiatigers.co.kr
					</a>
				</li>
				<li
					className="ListParagraph"
					style={{marginLeft: "44pt", textIndent: "-22pt"}}
				>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>
						업무시간안내 : (월~금) 8:30~17:30, (토~일) 휴무, 단 시즌 중에는 홈경기 스케줄 및
						경기시간에 따름
					</strong>
				</li>
			</ul>
			<p>
				<strong>
					<em>&lt;개인정보 보호관리자&gt;</em>
				</strong>
			</p>
			<ul className="awlist7" style={{margin: 0, paddingLeft: 0}}>
				<li style={{marginLeft: "44pt", marginBottom: 0, textIndent: "-22pt"}}>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>이름 : 조근준</strong>
				</li>
				<li style={{marginLeft: "44pt", marginBottom: 0, textIndent: "-22pt"}}>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>전화번호 : 070-7686-8034</strong>
				</li>
				<li style={{marginLeft: "44pt", marginBottom: 0, textIndent: "-22pt"}}>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>팩스번호 : 062-525-5350</strong>
				</li>
				<li style={{marginLeft: "44pt", marginBottom: 0, textIndent: "-22pt"}}>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>이메일 : </strong>
					<a
						href="mailto:kiatigers@kiatigers.co.kr"
						style={{textDecoration: "none", fontWeight: "bold"}}
					>
						kiatigers@kiatigers.co.kr
					</a>
				</li>
				<li style={{marginLeft: "44pt", textIndent: "-22pt"}}>
          <span
			  style={{
				  width: "16.97pt",
				  font: "7pt Times New Roman",
				  display: "inline-block",
			  }}
		  >
            &#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{" "}
          </span>
					<strong>
						업무시간안내 : (월~금) 8:30~17:30, (토~일) 휴무, 단 시즌 중에는 홈경기 스케줄 및
						경기시간에 따름
					</strong>
				</li>
			</ul>
			<p>
				<strong>
					&lt;기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
					문의하시기 바랍니다.&gt;
				</strong>
			</p>
			<ul style={{margin: 0, paddingLeft: 0}}>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>
						개인정보침해신고센터(privacy.kisa.or.kr, 국번없이 118, 한국인터넷진흥원)
					</strong>
				</li>
				<li
					style={{
						marginLeft: "27.6pt",
						marginBottom: 0,
						paddingLeft: "8.4pt",
						fontSize: "10pt",
					}}
				>
					<strong>대검찰청 신고센터(www.spo.go.kr, 국번없이 1301)</strong>
				</li>
				<li style={{marginLeft: "27.6pt", paddingLeft: "8.4pt", fontSize: "10pt"}}>
					<strong>
						경찰청 사이버안전국 (www.ctrc.go.kr, www.netan.go.kr, 국번없이 182)
					</strong>
				</li>
			</ul>
			<p>
				<strong>&#xa0;</strong>
			</p>
			<p style={{fontSize: "14pt"}}>
				<strong>11. 기타사항</strong>
			</p>
			<p>
				<strong>
					회사에 링크되어 있는 다른 회사의 웹사이트들이 개인정보를 수집하는 경우, 이용자 동의
					하에 개인정보가 제공된 이 후에는 본 "개인정보취급방침"이 적용되지 않음을
					알려드립니다.
				</strong>
			</p>
			<p>
				<strong>&#xa0;</strong>
			</p>
			<p>
				<strong>※ 이상의 변경된 KIA타이거즈 개인정보보호정책은 2017년 3월 31일부터 시행합니다.</strong>
			</p>
			<p>
				<strong>&#xa0;</strong>
			</p>
			<p>
				<strong>-&#xa0;공고일자&#xa0;: 2017년&#xa0; 03&#xa0;월&#xa0;30&#xa0;일</strong>
				<br/>
				<strong>
					-&#xa0;시행일자&#xa0;: 2017년&#xa0; 03&#xa0;월&#xa0;31&#xa0;일
				</strong>
			</p>
			<p>
				<strong>&#xa0;</strong>
			</p>
			<p>
				<strong>개인정보처리방침 변경 내역</strong>
			</p>
			<ul className="txtBar">
				<li>2013.03.30 ~ 2017.03.30 적용 <button onClick={() => setVersion("Old170330")}>보기</button></li>
			</ul>
			<p>
				<strong>&#xa0;</strong>
			</p>
		</div>
	);
};

export default Latest;
