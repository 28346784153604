import React from "react";

const Old200325 = ({setVersion}) => {
    return (
        <div>
            <article>
                <div className="oldHeader">
                    <h4>KIA타이거즈㈜ 개인정보 처리방침 / <span style={{fontSize:'0.8em', color:'gray'}}>2020년 03월 25일</span> </h4>
                    <button onClick={() => {setVersion("Latest")}}>현재 개인정보 처리방침 보기</button>
                </div>
                <div className="polyBox">
                    KIA타이거즈㈜(이하 '회사'라함)는 정보통신망 이용촉진 및 정보보호에
                    관한 법률, 개인정보보호법 등 정보통신서비스제공자가 준수하여야 할 관련
                    법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보
                    처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. 회사는
                    개인정보 처리방침을 통하여 이용자께서 제공하시는 개인정보가 어떠한
                    용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가
                    취해지고 있는지 알려드립니다.
                </div>
                <div className="polyBox">
                    1. 개인정보의 처리 목적
                    <br />
                    2. 개인정보의 수집 항목 및 수집 방법
                    <br />
                    3. 개인정보의 제3자 제공
                    <br />
                    4. 개인정보의 처리위탁
                    <br />
                    5. 개인정보의 보유 및 이용기간
                    <br />
                    6. 개인정보 파기절차 및 방법
                    <br />
                    7. 이용자 및 법정대리인의 권리와 그 행사방법
                    <br />
                    8. 개인정보 자동 수집장치의 설치/운영 및 거부에 관한 사항
                    <br />
                    9. 개인정보의 안전성 확보조치
                    <br />
                    10. 개인정보 보호책임자 및 담당자 안내
                    <br />
                    11. 적용범위
                    <br />
                    12. 고지의 의무
                    <br />
                </div>
            </article>
            <article>
                <h5>1. 개인정보의 처리 목적</h5>
                <p>회사는 고객의 개인정보를 수집 이용하는 목적은 다음과 같습니다.</p>
                <table className="polyTb">
                    <caption>개인정보의 처리 목적</caption>
                    <colgroup>
                        <col />
                        <col />
                    </colgroup>
                    <thead>
                    <tr>
                        <th>구분</th>
                        <th>이용목적</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>회원가입</th>
                        <td>
                            - 회원제 서비스 이용에 따른 회원식별
                            <br />
                            - 불량회원의 부정이용 방지와 비인가 사용 방지, 중복가입 방지
                            <br />
                            - 가입의사 확인, 가입 및 가입횟수 제한
                            <br />
                            - 미성년자의 확인
                            <br />
                            - 고객상담, 고객불만 접수 및 처리, 분쟁조정을 위한 기록보전
                            <br />- 고지사항 전달
                        </td>
                    </tr>
                    <tr>
                        <th>서비스 제공</th>
                        <td>
                            - 결제 서비스
                            <br />- 이벤트/경품당첨 결과안내 및 상품배송
                        </td>
                    </tr>
                    <tr>
                        <th>마케팅 광고</th>
                        <td>
                            - 회원 정보 및 구매 데이터에 대한 분석을 통한 구단의 각종 판매
                            상품 기획
                            <br />- 마케팅, 멤버십 정책 수립 및 프로그램 운영
                        </td>
                    </tr>
                    </tbody>
                </table>
            </article>
            <article>
                <h5>2. 개인정보의 수집 항목 및 수집 방법</h5>
                <table className="polyTb">
                    <caption>개인정보의 수집 항목 및 수집 방법</caption>
                    <colgroup>
                        <col />
                        <col />
                        <col />
                    </colgroup>
                    <thead>
                    <tr>
                        <th>구분</th>
                        <th>수집 및 이용 항목</th>
                        <th>수집 및 이용 목적</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>일반 회원</th>
                        <td>
                            -(필수) : ID, 비밀번호, 성명, 생년월일, 휴대전화번호, 이메일
                            <br />
                            -(선택) : SNS 계정번호 및 닉네임
                        </td>
                    </tr>
                    <tr>
                        <th>14세 미만 회원</th>
                        <td>
                            -(필수) : ID, 비밀번호, 성명, 생년월일, 휴대전화번호, 이메일
                            <br />
                            -(선택) : SNS 계정번호 및 닉네임
                            <br />
                            -법정대리인 본인 확인 정보(필수) : 법정대리인 이름, 휴대전화번호
                        </td>
                    </tr>
                    <tr>
                        <th>T-CLUB 가입 회원</th>
                        <td>
                            -(필수) : 이름, 아이디, 휴대전화번호, 이메일, 자택주소
                            <br />
                            - 본인인증
                            <br />
                            1. 휴대폰 인증 : 이름, 생년월일, 성별, 내외국인구분, 통신사, CI,
                            DI
                            <br />
                            2. i-PIN 인증 : 아이핀 ID/PW, 이름, 생년월일, CI, DI
                        </td>
                    </tr>
                    <tr>
                        <th>티켓예매 회원</th>
                        <td>-(필수) : 멤버십 번호, 이름, 휴대전화번호</td>
                    </tr>
                    <tr>
                        <th>회원 공통</th>
                        <td>
                            - 자동수집정보(필수) : 서비스 이용기록, 접속로그, 쿠키, 접속 IP
                            정보, 방문일시, 불량이용기록
                        </td>
                    </tr>
                    </tbody>
                </table>
                <p className="txtAdd">
                    회사는 개인정보를 사이트 회원가입 절차, 이벤트나 경품 행사 등을 통해
                    수집하며 이동 전화 및 유-무선 인터넷 서비스를 사용할 때 생성정보 수집
                    도구를 통한 방법(쿠키) 등으로도 개인정보를 수집합니다.
                </p>
            </article>
            <article>
                <h5>3. 개인정보의 제3자 제공</h5>
                <p>
                    회사는 이용자들의 개인정보를 "2. 개인정보의 수집 및 이용목적"에서
                    고지한 범위 내에서 사용하며, 다음의 경우를 제외하고는 이용자의 사전
                    동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의
                    개인정보를 제3자에게 제공하지 않습니다.
                    <br />
                    다만, 아래의 경우에는 예외로 합니다.
                </p>
                <ul className="txtBar">
                    <li>이용자들이 사전에 제공에 동의한 경우</li>
                    <li>
                        법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
                        따라 수사기관의 요구가 있는 경우
                    </li>
                    <li>
                        정보주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나
                        주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체
                        또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여  필요하다고
                        인정되는 경우
                    </li>
                    <li>
                        통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을
                        알아 볼 수 없는 형태로 개인정보를 제공하는 경우
                    </li>
                </ul>
            </article>
            <article>
                <h5>4. 개인정보의 처리위탁</h5>
                <p>
                    회사는 정보주체의 동의 없이 개인정보 처리 업무를 외부 업체에 위탁하지
                    않습니다. 회사는 아래와 같이 개인정보 처리 업무를 위탁하고 있으며,
                    관련 법령에 따라 위탁 계약 시 개인정보가 안전하게 관리 될 수 있도록
                    필요한 사항을 규정하고 있습니다.
                </p>
                <table className="polyTb">
                    <caption></caption>
                    <colgroup>
                        <col />
                        <col />
                        <col />
                    </colgroup>
                    <thead>
                    <tr>
                        <th>수탁업체</th>
                        <th>위탁업무 내용</th>
                        <th>보유 및 이용기간</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>엔에이치엔티켓링크 ㈜</th>
                        <td>티켓 예매, 유료 회원 결제</td>
                        <td rowSpan="7">회원탈퇴 시 또는 위탁계약 종료시</td>
                    </tr>
                    <tr>
                        <th>주식회사 이노션</th>
                        <td>
                            KIA 타이거즈 홈페이지, 회원앱 운영 관리, 멤버십 정책 수립,
                            마케팅 정책 수립, 마케팅 프로그램 운영, 구단의 각종 판매 상품
                            기획, 회원 정보 및 구매 데이터에 대한 분석
                        </td>
                    </tr>
                    <tr>
                        <th>엔에이치엔페이코 주식회사</th>
                        <td>KIA 타이거즈 앱 내 식음 판매 결제</td>
                    </tr>
                    <tr>
                        <th>씨제이프레시웨이㈜</th>
                        <td>경기장 내 식음 판매</td>
                    </tr>
                    <tr>
                        <th>㈜헬릭스테크</th>
                        <td>KIA 타이거즈 홈페이지, 회원앱 운영 관리</td>
                    </tr>
                    <tr>
                        <th>현대오토에버㈜</th>
                        <td>개인정보 처리시스템 인프라 운영관리</td>
                    </tr>
                    <tr>
                        <th>NICE평가정보㈜</th>
                        <td>
                            결제 시 본인인증, 14세 미만 회원가입 시 법정대리인 본인인증
                        </td>
                    </tr>
                    </tbody>
                </table>
            </article>
            <article>
                <h5>5. 개인정보의 보유 및 이용기간</h5>
                <p>
                    원칙적으로 계약해지 또는 회원탈퇴 등 개인정보 수집 및 이용목적이
                    달성된 후에는 해당 정보를 지체 없이 파기합니다.
                </p>
                <ul className="txtBar">
                    <li>
                        단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안
                        보존합니다.
                    </li>
                </ul>
                <p className="txtBold">&lt;회사 내부 방침에 의한 정보보유 사유&gt;</p>
                <p>
                    부정이용기록(부정가입, 징계기록 등의 비정상적 서비스 이용기록)으로
                    인한 정보 보존 필요가 있는 경우 아래의 명시된 항목과 기간동안
                    보관합니다.
                </p>
                <ul className="txtBar">
                    <li>보존 항목 : CI, 만 14세 미만 회원의 경우 법정대리인 CI</li>
                    <li>보존 이유 및 기간 : 부정 가입 및 이용 방지보존 기간, 10년</li>
                </ul>
                <p>
                    ※ ‘부정이용기록’이란 부정 가입 및 운영원칙에 위배되는 게시글 작성
                    등으로 인해 회사로부터 이용제한 등을 당한 기록입니다.
                </p>
                <p className="txtBold">&lt;관련법령에 의한 정보보유 사유&gt;</p>
                <p>
                    상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에
                    의하여 보존할 필요가 있는 경우, 회사는 관계법령에서 정한 일정한 기간
                    동안 회원정보를 보관합니다. <br />이 경우 회사는 보관하는 정보를 그
                    보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
                </p>
                <ul className="txtBar">
                    <li>
                        계약 또는 청약철회 등에 관한 기록 : 5년(전자상거래 등에서의
                        소비자보호에 관한 법률 제6조 제3항, 동법 시행령 제6조 제1항 제2호)
                    </li>
                    <li>
                        대금결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래 등에서의
                        소비자보호에 관한 법률 제6조 제3항, 동법 시행령 제6조 제1항 제3호)
                    </li>
                    <li>
                        소비자의 불만 또는 분쟁처리에 관한 기록 : 3년(전자상거래 등에서의
                        소비자보호에 관한 법률 제6조 제3항, 동법 시행령 제6조 제1항 제4호)
                    </li>
                    <li>
                        방문에 관한 기록 : 3개월(통신비밀보호법 제2조 제1항 제11호 마.목,
                        동법 제15조의 2 제2항, 동법 시행령 제41조 제2항 제2호)
                    </li>
                </ul>
            </article>
            <article>
                <h5>6. 개인정보의 파기절차 및 방법</h5>
                <p>
                    회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당
                    정보를 지체 없이 파기합니다.
                    <br />
                    파기절차 및 방법은 다음과 같습니다.
                </p>
                <p className="txtBold">&lt;파기절차&gt;</p>
                <ul className="txtBar">
                    <li>
                        이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의
                        DB로 옮겨져(종이의 경우 별도의 서류함) 내부방침 및 기타 관련 법령에
                        의한 정보보호 사유에 따라(보유 및 이용기간 참조) <br />
                        일정기간 저장된 후 파기됩니다. 별도 DB로 옮겨진 개인정보는 법률에
                        의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지
                        않습니다.
                    </li>
                </ul>
                <p className="txtBold">&lt;파기방법&gt;</p>
                <ul className="txtBar">
                    <li>
                        종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                        파기합니다.
                    </li>
                    <li>
                        전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
                        방법을 사용하여 삭제합니다.
                    </li>
                </ul>
            </article>

            <article>
                <h5>7. 이용자 및 법정대리인의 권리와 그 행사방법</h5>
                <ul className="txtBar">
                    <li>
                        이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만
                        14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를
                        요청할 수도 있습니다.
                    </li>
                    <li>
                        이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는
                        '개인정보변경'(또는 '회원정보수정' 등)을 통해서 하실 수 있으며,
                        가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를
                        거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
                    </li>
                    <li>
                        혹은 개인정보보호책임자에게 서면, 전화, 이메일, 모사전송(FAX) 등을
                        통해서 개인정보 열람요구, 정정요구, 삭제요구, 처리정지 요구를 하실
                        수 있으며, 회사는 이에 대해 지체 없이 조치하겠습니다.
                    </li>
                    <li>
                        이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을
                        완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한
                        잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를
                        제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
                    </li>
                    <li>
                        회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
                        개인정보는 "5. 개인정보의 보유 및 이용기간"에 명시된 바에 따라
                        처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리합니다.
                    </li>
                    <li>
                        이러한 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
                        대리인을 통해서도 하실 수 있으며, 이 경우에는 법에서 정한 위임장을
                        제출하셔야 합니다.
                    </li>
                </ul>
            </article>

            <article>
                <h5>8. 개인정보 자동 수집장치의 설치/운영 및 거부에 관한 사항</h5>
                <p>
                    회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의
                    정보를 수시로 저장하고 찾아내는 '쿠키(cookie)' 등을 운용합니다. 쿠키란
                    웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에
                    보내는 소량의 정보이며 이용자들의 컴퓨터 하드디스크에 저장됩니다.
                    회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
                </p>
                <p className="txtBold">&lt;쿠키 등 사용 목적&gt;</p>
                <ul className="txtBar">
                    <li>
                        회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
                        관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수
                        파악 등을 통한 타켓 마케팅 및 개인 맞춤 서비스 제공
                    </li>
                    <li>
                        이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는
                        웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
                        저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
                        있습니다.
                    </li>
                    <li></li>
                </ul>
                <p className="txtBold">&lt;쿠키 설정 거부 방법&gt;</p>
                <ul className="txtBar">
                    <li>
                        예 : 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의
                        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다
                        확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
                    </li>
                    <li>
                        설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 >
                        인터넷 옵션 > 개인정보
                    </li>
                    <li>
                        단, 귀하께서 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부
                        서비스는 서비스 제공에 어려움이 있을 수 있습니다.
                    </li>
                </ul>
            </article>
            <article>
                <h5>9. 개인정보의 안전성 확보조치</h5>
                <p>
                    회사는 이용자의 개인정보를 안전하게 관리함에 있어 개인정보가 분실,
                    도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은
                    기술적, 관리적, 물리적 대책을 마련하고 있습니다.
                </p>
                <p className="txtBold">&lt;기술적 대책&gt;</p>
                <ul className="txtBar">
                    <li>
                        개인정보의 안전한 저장 및 전송 등을 위하여 법령이 정한 기준을
                        준수하고 있습니다.
                    </li>
                    <li>
                        회사는 백신 프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를
                        방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로
                        업데이트 되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시
                        이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
                    </li>
                </ul>
                <p className="txtBold">&lt;관리적 대책&gt;</p>
                <ul className="txtBar">
                    <li>
                        회사는 정보주체를 직접 상대로 하여 영업 및 마케팅 업무를 수행하는
                        자, 개인정보관리업무를 수행 하는 자, 기타 업무상 개인정보의 취급이
                        불가피한자 등으로 개인정보에 대한 접근권한을 제한하고 있습니다.
                    </li>
                    <li>
                        회사는 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여
                        아래와 같이 개인정보보호책임자를 지정하고 있습니다.
                    </li>
                </ul>
                <p className="txtBold">&lt;물리적 대책&gt;</p>
                <ul className="txtBar">
                    <li>서버실, 자료보관실 등의 접근을 통제하고 있습니다.</li>
                </ul>
            </article>
            <article>
                <h5>10. 개인정보 보호책임자 및 담당자 안내</h5>
                <p>
                    회사는 이용자의 개인정보를 보호하고 개인정보와 관련된 문의사항,
                    불만처리를 위해 다음과 같은 책임자를 지정하고 있습니다. 회사의
                    서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 아래의
                    개인정보 보호책임자 혹은 개인정보 보호담당자로 문의하실 수 있습니다.
                </p>
                <div className="polyBox2 clear">
                    <div>
                        <p className="txtBold">개인정보보호책임자</p>
                        <p>
                            - 성명 및 직위 : 홍경화 상무
                            <br />
                            - 연락처 : 070-7686-8053
                            <br />- 이메일 : kiatigers@kiatigers.co.kr
                        </p>
                    </div>
                    <div>
                        <p className="txtBold">개인정보 보호담당자</p>
                        <p>
                            - 성명 및 직위 : 박청아 매니저
                            <br />
                            - 연락처 : 062-7686-8053
                            <br />- 이메일 : kiatigers@kiatigers.co.kr
                        </p>
                    </div>
                </div>
                <p className="txtBold">
                    &lt;기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
                    기관에 문의하시기 바랍니다.&gt;
                </p>
                <ul className="txtBar">
                    <li>
                        개인정보침해신고센터(privacy.kisa.or.kr, 국번없이 118,
                        한국인터넷진흥원)
                    </li>
                    <li>대검찰청 신고센터(www.spo.go.kr, 국번없이 1301)</li>
                    <li>
                        경찰청 사이버안전국 (www.ctrc.go.kr, www.netan.go.kr, 국번없이 182)
                    </li>
                </ul>
            </article>
            <article>
                <h5>11. 적용범위</h5>
                <p>
                    회사에 링크되어 있는 다른 회사의 웹사이트들이 개인정보를 수집하는
                    경우, 이용자 동의 하에 개인정보가 제공된 이 후에는 본 "개인정보
                    처리방침"이 적용되지 않음을 알려드립니다.
                </p>
            </article>
            <article>
                <h5>12. 고지의 의무</h5>
                <p>
                    본 개인정보 처리방침에 대한 추가, 삭제 및 수정이 있을 경우에는
                    시행하는 날로부터 최소 7일전에 공지사항 등을 통해 변경 및 내용 등을
                    공지하도록 하겠습니다. 다만 이용자의 소중한 권리 또는 의무에 중요한
                    내용 변경이 발생하는 경우 시행일로부터 최소 30일 전에 공지하도록
                    하겠습니다.
                </p>
                <br/>
                <ul className="txtBar">
                    <li>2013.03.30 ~ 2017.03.30 적용 <button onClick={() => setVersion("Old170330")}>보기</button></li>
                    <li>2017.03.31 ~ 2017.05.16 적용 <button onClick={() => setVersion("Old170516")}>보기</button></li>
                    <li>2017.05.17 ~ 2018.07.12 적용 <button onClick={() => setVersion("Old180713")}>보기</button></li>
                    <li>2018.07.13 ~ 2020.02.10 적용 <button onClick={() => setVersion("Old200210")}>보기</button></li>
                    <li>2020.02.11 ~ 2020.03.24 적용 <button onClick={() => setVersion("Old200324")}>보기</button></li>
                </ul>
                <br className="pc"/>
            </article>
        </div>
    )
}

export default Old200325;