import axios from "axios";
import * as AuthHelper from "../helpers/Auth.helper";
import CommonError from "../errors/Common.error";
const psnToken = "dummyToken";
const baseUrl = "/v1";
const baseUrl2 = "/v2";

export const login = async (id, pw) => {

  const data = {
    'user.simpleId': id,
    'user.memberPw': pw,
    'pnsToken': psnToken
  }

  // const headers = {
  //     'Content-Type': 'application/x-www-form-urlencoded'
  // }

  return axios
    .post(`${baseUrl2}/auth/login`, data)
    .then(({ status, data }) => {

      //통합로그인 이동
      if (status === 200 && data.status === 302) {
        alert("통합회원서비스 이용을 위해 다음 페이지로 이동합니다.");
        window.location.href = data.data.redirectUrl;
        return;
      }

      if (data.status === 2018 || data.status === 2020) {
        return data;
      } else if (data.status !== 0) {
        alert(data.message);
        throw new Error(data.message);
      }
      return data.data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const logout = async (accessToken, refreshToken) => {
  return axios
    .post(`${baseUrl}/auth/logout`)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const checkPw = async (pw) => {
  const data = {
    'user.memberPw': pw
  }

  return axios
    .post(`${baseUrl}/auth/pw`, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

// https://api.kiatigers.info/v1/auth/refreshAccessToken
export const refreshAccessToken = async (_) => {
  const memberId = AuthHelper.getMemberId();
  if (!memberId) {
    AuthHelper.removeLoginInfo();
    alert("정상적인 접근이 아닙니다.(refreshAccessToken");
    window.location.href = "/"
  }
  const data = {
    'user.memberId': memberId
  }

  return axios
    .post(`${baseUrl}/auth/refreshAccessToken`, data)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data.message);
      }
      return data;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err.message);
    });
};

export const getUserBoardPermission = async () => {
  return axios
    .get(`${baseUrl}/user/boardPermission`)
    .then(({ status, data }) => {
      if (data.status !== 0) {
        throw new Error(data);
      }
      return data.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const turnNormalUser = async (type, params) => {
  const urlType = type === "MOBILEM" ? "phone" : "ipin";
  const {id, niceData} = params;

  console.log("params :::: ", params);
  console.log("type ::::", type);

  return axios
    .get(
      `${baseUrl}/user/children/re-cert/${urlType}?simpleId=${id}&niceData=${niceData}`
    )
    .then((res) => {
      if (res.status === 200) {
        if (res.data.data.sMessage) {
          alert(res.data.data.sMessage);
        }

        return {status: 200, data: res.data};
      } else {
        alert(res.data.message)
      }
    })
    .catch((e) => console.error(e));
};
