import React, { Component } from "react";
import SubHeadComponent from "../../common/SubHead.component";
import SubMenuComponent from "../../common/SubMenu.component";
import ContComponent from "./Cont.component";

import AnalyticsContext from "../../../../context/AnalyticsContext";
import * as CommonService from "../../../../services/Common.service";

class ListContainer extends Component {
  static contextType = AnalyticsContext;

  constructor(props) {
    super(props);

    this.state = {
      list: [],
    };
  }

  componentDidMount = async (_) => {
    const currentAnalyticsVal = this.context.state;
    const prevUrl = currentAnalyticsVal.currentUrl;
    this.context.action(currentAnalyticsVal);

    currentAnalyticsVal.deps2 = "02";
    currentAnalyticsVal.deps3 = "01";
    currentAnalyticsVal.prevUrl = prevUrl;
    currentAnalyticsVal.currentUrl = window.location.href;

    await CommonService.insertAnalytics(this.context.state);

    this.updateList(this.props.entryTabNum);
  };

  onClickBtnEntryTab = (evt, tabNum) => {
    this.props.setEntryTabNum(tabNum);
  };

  componentDidUpdate = (prevProps) => {
    console.log("componentDidUpdate", this.props.entryTabNum, this.props.coachingStaffList);
    if (this.props.entryTabNum !== prevProps.entryTabNum) {
      this.updateList(this.props.entryTabNum);
    } else if (this.props.coachingStaffList !== prevProps.coachingStaffList) {
      this.updateList(this.props.entryTabNum);
    }
  };

  updateList = (entryTabNum) => {
    let list = [];
    for (let i = 0; i < this.props.coachingStaffList.length; i++) {

      const coachingStaffObj = this.props.coachingStaffList[i];
      console.log(entryTabNum, coachingStaffObj);
      if (coachingStaffObj.entry === entryTabNum) {
        list.push(coachingStaffObj);
      }
    }
    console.log("list", list);
    this.setState({ list });
  };

  onClickBtnPlayer = (evt, pcode) => {
    if (!pcode) {
      alert("코칭스텝 정보를 준비중입니다.");
    } else {
      // alert("3월 31일 16시 오픈 예정입니다.");
      const url = `${this.props.match.path}/${pcode}`;
      this.props.history.push(url);
    }
  };

  render = (_) => (
    <div className="container">
      <SubHeadComponent subMenuName={this.props.subMenuName} />
      <div className="sbHead_appView tb"></div>
      {process.env.REACT_APP_PREPARING_PLAYERS_PAGE_YN === "Y" ? (
        <div className="mb_cont sub_cont player">
          <div className="mb_contIn">
            <SubMenuComponent
              subMenu={this.state.subMenu}
              subMenuName={this.state.subMenuName}
            />
            <div style={{ marginTop: "50px" }}>
              <div className="txt clear">
                <img
                  src="/img/seasonstop_2024_pc.png"
                  alt="season_off_pc"
                  className="pc"
                />
                <img
                  src="/img/seasonstop_2024_pc.png"
                  className="mb"
                  style={{width: "100%"}}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ContComponent
          list={this.state.list}
          uri={this.props.match.path}
          subMenu={this.props.subMenu}
          subMenuName={this.props.subMenuName}
          entryTabNum={this.props.entryTabNum}
          onClickBtnEntryTab={this.onClickBtnEntryTab}
          onClickBtnPlayer={this.onClickBtnPlayer}
        />
      )}
    </div>
  );
}

export default ListContainer;
