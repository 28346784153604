import React from 'react';
import SubMenuComponent from '../common/SubMenu.container';
const ContServiceComponent = props => (
    <div className="mb_cont sub_cont policy">
        <div className="mb_contIn">
            <SubMenuComponent
                subMenu={props.subMenu} />
            <article>
                <h4>KIA타이거즈 서비스 이용약관</h4>
                <h5>제 1 조 (목적)</h5>
                <p>이 약관은 KIA타이거즈 주식회사(이하 “회사”)가 운영하는 사이트(tigers.co.kr, 이하 “당사이트”) 및 모바일 앱(사이트와 모바일 앱 모두를 지칭하는 경우, 이하 ”당사이트 등”)에서 제공하는 모든 서비스(이하 “서비스”)의 이용과 관련하여 “회사”와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
            </article>
            <article>
                <h5>제 2조 (정의)</h5>
                <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p><br />
                <p>① “서비스”라 함은 “회원” 또는 “비회원”이 구현되는 단말기(PC, TV, 휴대형 단말기 등의 각종유무선장치를 포함)의 종류와 상관 없이 이용할 수 있는 서비스를 의미합니다. 서비스 상세 내역은 제 12조와 같습니다. <br />
                    ② “당 사이트 등”은 회사가 서비스를 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 회원이 열람 및 이용할 수 있도록 설정한 가상의 서비스 공간을 말합니다.<br />
                    ③ “회원”이라 함은 회사에서 제공하는 서비스를 이용하고자 ‘당사이트 등’에 접속하여 본 약관에 따라 이용계약을 체결한 고객을 말합니다.<br/>
                    ④ “비회원”이라 함은 회원에 가입하지 않고 ‘당사이트 등’에서 제공하는 서비스를 이용하는 자를 말합니다.<br/>
                    ⑤ “회원”은 유료, 무료 회원을 모두 지칭합니다.<br/>
                    1) 유료회원: T-CLUB회원, 어린이회원<br/>
                    “유료회원”이란 회사가 년 단위로 모집하는 회원 계약에 대하여 연회비를 납부하고 개인정보를 제공하여 회원등록을 한 자로서 회사가 제공하는 모든 서비스(회원 계약 시 안내된 제공 혜택 포함)를 이용할 수 있는 자를 말합니다.<br/>
                    2) 무료회원: 홈페이지 회원, 모바일 앱 회원<br/>
                    “무료회원”이란 개인정보를 제공하여 회원등록을 한 자로서 회사가 제공하는 서비스(홈페이지, 모바일 앱) 중 무료회원에게 인정되는 서비스를 이용할 수 있는 자를 말합니다.<br/>

                    ⑥ "아이디(ID)"라 함은 "회원"의 식별과 "서비스" 이용을 위하여 "회원"이 정하고 “당사이트”가 승인하는 문자와 숫자의 조합을 의미합니다.<br />
                    ⑦ "비밀번호"라 함은 "회원"이 부여 받은 "아이디와 일치되는 "회원"임을 확인하고 비밀보호를 위해 "회원" 자신이 정한 문자 또는 숫자의 조합을 의미합니다.<br />
                    ⑧ "포인트"라 함은 서비스의 효율적 이용을 위해 “당사이트” 임의로 책정 또는 지급, 조정할 수 있는 재산적 가치가 없는 "서비스" 상의 가상데이터를 의미합니다.<br />
                    ⑨ "게시물"이라 함은 "회원"이 "서비스"를 이용함에 있어 "서비스상"에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.</p>
            </article>
            <article>
                <h5>제3조 (약관의 게시와 개정)</h5>
                <p>① “당사이트”는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 서비스 초기화면에 게시합니다.<br />
                    ② “당사이트”는 "약관의규제에관한법률", "정보통신망이용촉진및정보보호등에관한법률(이하 "정보통신망법")" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br />
                    ③ 회사는 약관을 개정할 경우, 적용일자 및 개정사유를 명시하여 현행 약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 최소 7일전부터 적용일자 전일까지 공지합니다<br />
                    ④ “당사이트”가 전항에 따라 개정약관을 공지하면서 회원에게 30일기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.<br />
                    ⑤ 회원이 개정약관의 적용에 동의하지 않는 경우에 회원은 이메일(kiatigers@kiatigers.co.kr) 또는 홈페이지 내 1:1 문의 등을 통해 회사에 거부의사를 표시하고 이용계약을 해지할 수 있습니다.</p>
            </article>
            <article>
                <h5>제 4 조 (운영정책 및 약관의 해석)</h5>
                <p>① “당사이트”는 “당사이트”의 서비스 유지 및 회원의 보호 등을 위해 별도의 운영정책을 운영할 수 있으며, “회원”은 “당 사이트”가 정한 운영정책을 반드시 확인하고 준수하여야 합니다.<br />
                    ② 본 약관에서 명시되지 않은 사항이나 해석에 대해서는 운영정책, 이용안내, 관계법령에 따릅니다.</p>
            </article>
            <article>
                <h5>제 5 조 (회원가입 및 이용계약체결)</h5>
                <p>① 이용계약은 "회원"이 되고자 하는 자(이하 "가입신청자")가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 “당사이트”가 이러한 신청에 대하여 승낙함으로써 체결됩니다.<br />
                    ② 회원은 무료, 유료회원으로 구분되며, 유료회원에 대한 가입조건, 회원자격, 가입혜택, 제공기간, 가입 금액 등 은 매년 “당사이트”를 통해 공지합니다.<br />
                    1) 무료회원: 홈페이지 회원, 모바일 앱 회원<br />
                    2) 유료회원: T-CLUB 회원, 어린이회원<br />
                    ③ 회사는 다음 각호에 해당하는 경우 가입신청자의 신청에 대하여 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.<br />
                    1)실명이 아니거나 타인의 명의를 이용한 경우<br />
                    2)허위의 정보를 기재하거나, "당사이트"가 제시하는 내용을 기재하지 않은 경우<br />
                    3) 14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니한 경우<br />
                    4) 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타규정한 제반사항을 위반하며 신청하는 경우<br />
                    5) 사회의 안녕 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때<br />
                    6) 타인의 “당사이트” 서비스 이용을 방해하는 경우<br />
                    7) 타인의 개인정보를 수집 및 도용하는 등의 행위를 한 경우<br />
                    8) “당사이트” 이용시 발생한 명예훼손, 모욕, 폭력죄(폭행) 등으로 법적 처벌을 받은 경우<br />
                    9) 이용자가 정상적인 이용방법이 아닌 방법으로 서비스를 이용하거나, 비정상적인 티켓 구매(매크로 등 프로그램 이용, 반복적인 구매/취소 등)및 재 판매를 통해 재산상의 이익을 취득하고자 하여 다른 이용자에게 피해를 주는 경우<br/>
                    10) 기타 “당사이트”가 정한 회원가입요건이 미비 되었을 때<br />
                    ④ 제1항에 따른 신청에 있어 “당사이트”는 "회원"의 종류에 따라 전문기관을 통한 본인인증을 요청할 수 있습니다.<br />
                    ⑤ “당사이트”는 서비스관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.<br />
                    ⑥ ③항과 ⑤항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, “당사이트”는 원칙적으로 이를 가입신청자에게 알리도록 합니다.<br />
                    ⑦ 이용계약의 성립시기는 “당사이트”가 가입완료를 신청절차상에서 표시한 시점으로 합니다.<br />
                    ⑧ “당사이트”는 "회원"에 대해 “당사이트”의 정책에 따라 등급별로 구분하여 서비스메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.<br />
                    ⑨ “당사이트”는 “회원”에 대하여“청소년보호법”등에 따른 등급 및 연령준수를 위해 이용제한이나 등급별 제한을 할 수 있습니다.
			</p>
            </article>
            <article>
                <h5>제 6 조 (회원정보의 변경)</h5>
                <p>① "회원"은 개인정보관리 화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스관리를 위해 필요한 실명, 아이디, 생년월일 등은 수정이 불가능합니다.<br />
                    ② 아이디는 원칙적으로 변경이 불가하며 부득이한 사유로 인하여 변경하고자 하는 경우에는 해당아이디를 해지하고 재가입 해야 합니다.<br />
                    ③ 아이디는 다음 각호에 해당하는 경우에는 이용고객 또는 회사의 요청으로 변경할 수 있습니다.<br />
                    1) 아이디가 회원의 전화번호 또는 주민등록번호 등으로 등록되어 사생활침해가 우려되는 경우 <br />
                    2) 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우 <br />
                    3) 기타 합리적인 사유가 있는 경우<br />
                    ④ "회원"은 회원 가입시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편기타방법으로 “당사이트”에 대하여 그 변경사항을 알려야 합니다.<br />
                    ⑤ 제4항의 변경사항을 “당사이트”에 알리지 않아 발생한 불이익에 대하여 “당사이트”는 책임 지지 않습니다.
			</p>
            </article>
            <article>
                <h5>제 7 조 (개인정보보호의무)</h5>
                <p>회사는 “개인정보 보호법” 및 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사에 링크되어 있는 다른 회사의 사이트들이 개인정보를 수집하는 경우, 정보주체의 동의 하에 개인정보가 제공된 이후에는 회사의 개인정보처리방침이 적용되지 않습니다.</p>
            </article>
            <article>
                <h5>제 8 조 ("회원"의 "아이디" 및 "비밀번호"의 관리에 대한 의무)</h5>
                <p>① "회원"의 "아이디"와 "비밀번호"에 관한 관리책임은 "회원"에게 있으며, 이를 제3자가 이용하도록 하여서는 안됩니다.<br />
                    ② “당사이트” 는 "회원"의 "아이디"가 개인정보유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 "당사이트" 및 "당사이트"의 운영자로 오인한 우려가 있는 경우, 해당 "아이디"의 이용을 제한할 수 있습니다.<br />
                    ③ "회원"은 "아이디" 및 "비밀번호"가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 "당사이트"에 통지하고 "당사이트"의 안내에 따라야 합니다.<br />
                    ④ 제3항의 경우에 해당 "회원"이 "당사이트"에 그 사실을 통지하지 않거나, 통지한 경우에도 "당사이트"의 안내에 따르지 않아 발생한 불이익에 대하여 "당사이트"는 책임지지 않습니다.
			</p>
            </article>
            <article>
                <h5>제 9 조 ("회원"에 대한 통지)</h5>
                <p>① "당사이트"가 "회원"에 대한 통지를 하는 경우 이 약관에 별도규정이 없는 한 회원정보상의 전자우편주소 또는 서비스 내 전자쪽지 등으로 할 수 있습니다.<br />
                    ② "당사이트"는 "회원" 전체에 대한 통지의 경우 7일 이상 "당사이트"의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.<br />
                    ③ “당사이트”는 ①항의 방법에 따라 정상적으로 발송하거나, ②항의 방법에 따라 게시하는 경우 통지가 도달된 것으로 보며, “회원”이 “당사이트”에 제공한 전자우편주소가 사실과 다르거나 “회원 "이 전자쪽지 등의 통지를 확인하지 않아 발생한 문제에 대하여 “당사이트”는 책임을 지지 않습니다.<br />
                </p>
            </article>
            <article>
                <h5>제 10 조 ("당사이트"의 의무)</h5>
                <p>① "당사이트"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.<br />
                    ② "당사이트"는 "회원"이 안전하게 "서비스"를 이용할 수 있도록 개인정보(신용정보포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다.<br />
                    ③ "당사이트"는 이용자의 귀책사유로 인한 서비스이용장애에 대하여 책임을 지지 않습니다.<br />
                    ④ “당사이트”는 사이트 이용과 관련하여 “회원”으로부터 제기된 의견이나 불만이 정당하다고 인정될 경우 이를 처리하여야 하며, “당사이트” 내 게시판, 전자우편 등을 통하여 “회원”에게 처리과정 및 결과를 전달할 수 있습니다.
			</p>
            </article>
            <article>
                <h5>제 11 조 ("회원"의 의무)</h5>
                <p>① 회원이 서비스를 이용할 때 아래 각 호의 행위는 하여서는 안됩니다.<br/>
                    1) 가입 신청 또는 변경 시 허위 사실을 기재한 경우<br/>
                    2) 다른 회원의 아이디, 비밀번호를 도용하거나 부정하게 사용하는 행위<br/>
                    3) 다른 사람의 명의를 사용하거나 명의자의 허락 없이 문자메시지(SMS) 인증 등을 수행하는 행위<br/>
                    4) 타인의 명예를 손상시키거나 불이익을 주는 행위<br/>
                    5) 게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 행위<br/>
                    6) 회사 또는 제3자의 저작권 등 기타 권리(비영리 목적 복제, 출판, 방송 등 사용)를 침해하는 행위<br/>
                    7) 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 음성 등을 타인에게 유포하는 행위<br/>
                    8) 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 미 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위<br/>
                    9) 서비스의 운영을 고의로 방해하거나 안정적 운영을 방해할 수 있는 정보 및 수신자의 명시적인 수신거부의사에 반하여 광고성 정보 또는 스팸메일(Spam Mail)을 전송하는 행위<br/>
                    10) 회사의 동의 없이 서비스 또는 이에 포함된 소프트웨어의 일부를 복사, 수정, 배포, 판매, 양도, 대여, 담보 제공하거나 타인에게 그 이용을 허락하는 행위와 소프트웨어를 역설계하거나 소스 코드의 추출을 시도하는 등 서비스를 복제, 분해 또는 모방하거나 기타 변형하는 행위<br/>
                    11) 타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위<br/>
                    12) 다른 회원의 개인정보를 수집, 저장, 공개하는 행위<br/>
                    13) 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적 또는 그 이외의 사유로 허위의 정보(사실)를 유통시키는 행위<br/>
                    14) 수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상 또는 영상을 계속하여 상대방에게 도달하게 하여 상대방의 일상적 생활을 방해하는 행위<br/>
                    15) 출신(국가, 지역 등)·인종·외양·장애 및 질병 유무·사회 경제적 상황 및 지위·종교·연령·성별·성정체성·성적 지향 또는 기타 정체성 요인 등을 이유로 인간으로서의 존엄성을 훼손하거나, 폭력을 선동하거나, 차별·편견을 조장하는 행위<br/>
                    16) 회사의 직원이나 서비스의 관리자를 가장하거나 사칭하여 또는 타인의 명의를 모욕하여 글을 게시하거나 전자우편(E-mail)을 발송하는 행위<br/>
                    17) 수동과 자동을 불문하고 부정한 방법을 사용하거나 타인의 조회(클릭)을 유도하여 인위적으로 조회를 발생시키는 행위<br/>
                    18) 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 포함)의 전송 또는 게시 행위<br/>
                    19) 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 전자우편(E-mail) 등으로 발송하는 행위<br/>
                    20) 기타 불법, 부정한 행위<br/>
                    21) 일정한 내용을 지속적으로 게시 및 전송하여 타인의 서비스 이용을 방해하는 행위<br/>
                    22) 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위<br/>
                    23) 기타 회사의 운영정책에 위배되는 모든 행위<br/>
                    ② 회사는 회원이 관련 법령, 본 약관 또는 정책을 준수하지 않는 경우, 회원의 위반행위를 조사할 수 있고, 회원의 계정·서비스 이용을 일정기간 또는 계속하여 중단하거나, 재가입에 제한을 둘 수 있습니다. 또한 회원이 서비스와 관련된 설비의 오작동이나 시스템의 파괴 및 혼란을 유발하는 등 서비스 제공에 악영향을 미치거나 안정적 운영을 심각하게 방해한 경우, 회사는 이러한 위험 활동이 확인된 계정들에 대하여 이용제한을 할 수 있습니다. 다만, 회원은 이용제한과 관련하여 조치 결과가 불만족스러울 경우 홈페이지 1:1 문의 또는 회사 전자우편(E-mail)을 통해 이의를 제기할 수 있습니다.
                </p>
            </article>
            <article>
                <h5>제 12 조 ("서비스"의 제공 및 변경) </h5>
                <p>① 회사는 ‘당사이트 등’을 통하여 다음과 같은 서비스를 제공합니다.<br/>
                    1) 회사에 대한 홍보 내용<br/>
                    2) 회원 이용 서비스<br/>
                    3) 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결<br/>
                    4) 구매계약이 체결된 재화의 배송<br/>
                    5) 구매계약 및 배송에 관련된 용역 제공<br/>
                    6) 해당 용역에 대한 고객 응대 및 처리<br/>
                    7) 기타 회사가 정하는 업무
                    <br />
                    ② "서비스"의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당사항에 대해 공지하여야 합니다.<br />
                    ③ "당사이트"는 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 "회원"에게 별도의 보상을하지 않습니다.
			</p>
            </article>
            <article>
                <h5>제 13 조 (구매신청 및 개인정보 제공 동의 등)</h5>
                <p>① 고객은 ‘당사이트 등’에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, 회사는 고객이 구매신청을 함에 있어서 다음의 각 내용을 ’당사이트 등’을 통하여 알기 쉽게 제공하여야 합니다.<br/>
                    1) 재화 등의 검색 및 선택<br/>
                    2) 주소 등 주문 및 배송에 필요한 정보의 입력<br/>
                    3) 약관내용, 청약 철회권이 제한되는 서비스, 배송비, 설치비 등의 비용부담과 관련한 내용에 대한 확인<br/>
                    4) 이 약관에 동의하고 제3호의 사항을 확인하거나 거부하는 표시<br/>
                    5) 재화 등의 구매신청 및 이에 관한 확인 또는 회사의 확인에 대한 동의<br/>
                    6) 결제 방법의 선택
                </p>
            </article>
            <article>
                <h5>제 14 조 (구매계약의 성립)</h5>
                <p>① 회사는 고객의 구매신청에 대하여 다음 각호에 해당하면 고객의 청약에 대하여 승낙하지 않을 수 있습니다. 다만, 미성년자가 체결한 계약의 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 그 법정대리인은 해당 계약을 취소할 수 있습니다.<br/>
                    1) 신청 내용에 허위, 기재누락, 오기가 있는 경우<br/>
                    2) 미성년자가 보호자의 승낙 없이 구매하는 경우<br/>
                    3) 기타 구매신청에 승낙하는 것이 기술상 현저히 지장이 있다고 판단하는 경우<br/>
                    ② 구매신청에 대한 회사의 승낙은 주문 완료 후 전자우편(E-mail)의 형태로 고객에게 전달되며, 이 전자우편이 고객에게 도달한 시점에 계약이 성립된 것으로 간주합니다.<br/>
                    ③ 제2항에 따른 승낙의 의사표시에는 고객의 구매 신청에 대한 확인 및 판매가능여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함합니다.
                </p>
            </article>
            <article>
                <h5>제 15조 (지급방법)</h5>
                <p>‘당사이트 등’에서 구매한 재화 등에 대한 대금지급 방법은 다음 각호의 하나로 할 수 있으며, 회사는 고객의 지급방법 선택과 관련하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수하지 않습니다.</p>
                <p>1) 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체<br/>
                    2) 선불카드, 직불카드, 신용카드 등의 각종 카드 결제<br/>
                    3) 온라인 무통장입금<br/>
                    4) 휴대폰 결제<br/>
                    5) 포인트 등 “회사”가 지급한 포인트에 의한 결제 (일부 상품 제외)<br/>
                    6) 기타 관계 법령 또는 회사의 정책에 따라 허용되어 사전 공지한 지급 방법
                </p>
            </article>
            <article>
                <h5>제 16조 (수신확인통지ㆍ구매신청 변경 및 취소)</h5>
                <p>① 회사는 고객의 구매신청이 있는 경우 고객에게 수신확인통지를 합니다.<br/>
                    ② 수신확인통지를 받은 고객은 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 ‘당사이트 등’의 공지사항 혹은 전화를 통해 구매신청변경 및 취소를 요청할 수 있고 사이트는 고객의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 청약철회 규정에 따릅니다.<br/>
                    ③ 고객이 변경 및 취소를 요구하였으나 이미 제품이 발송되어 회사가 이를 수용할 수 없는 경우 고객변심으로 인한 반품으로 처리되며 이에 관하여는 청약철회의 절차에 따라 처리됩니다.

                </p>
            </article>
            <article>
                <h5>제 17 조 (재화 등의 공급)</h5>
                <p>① 회사는 고객과 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 고객이 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, 회사가 이미 재화 등에 대한 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 재화 등의 공급을 위하여 필요한 조치를 취합니다. 이 때 회사는 고객이 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.<br/>
                    ② 회사는 고객이 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 사이트에 명시합니다. 만약 회사 고의, 과실로 약정 배송기간을 초과한 경우에는 그로 인한 고객의 손해를 배상하여야 합니다. 다만 회사가 고의과실이 없음을 입증한 경우에는 그러하지 아니합니다.<br/>
                    ③ 회사는 배송과정에서 상품이 파손되지 않도록 적절한 포장을 하여야 하며 “전자상거래 등에서의 소비자 보호에 관한 법률” 규정에 따라 고객의 청약에 따라 재화 등을 공급하는 경우 그 내용을 기재한 서면(이하 “공급서”라 한다)을 재화 등에 첨부하여 고객에게 송부합니다.

                </p>
            </article>
            <article>
                <h5>제 18 조 (환급)</h5>
                <p>① "당사이트"는 "회원"이 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 "회원"에게 제공할 수 있습니다. 다만, "회원"은 관련법에 따른 거래관련정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신거절을 할 수 있습니다.<br />
                    ② ①항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 "회원"의 사전동의를 받아서 전송합니다. 다만, "회원"의 거래관련정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.<br />
                    ③ "당사이트"는 "서비스"의 운영과 관련하여 서비스화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 "회원"은 수신거절을 "당사이트"에 할 수 있습니다.<br />
                    ④ ③항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 "회원"의 사전동의를 받아서 전송합니다. 다만, "회원"의 거래관련정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.<br />
                    ⑤ 이용자(회원, 비회원포함)는 “당사이트”가 제공하는 서비스와 관련하여 게시물 또는 기타정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.
                </p>
            </article>
            <article>
                <h5>제 19 조 (정보의 제공 및 광고의 게재)</h5>
                <p>회사는 고객이 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 고객에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 7영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
                </p>
            </article>
            <article>
                <h5>제 20조 (청약철회 등)</h5>
                <p>① ‘당사이트 등’에서 재화나 용역을 구매한 회원 등은 재화 등을 공급받거나 공급이 시작된 날로부터 7일 이내에 청약을 철회할 수 있습니다.<br/>
                    ② 고객은 재화 등을 배송 받은 경우 다음 각호에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.<br/>
                    1) 고객에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)<br/>
                    2) 고객의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우<br/>
                    3) 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우<br/>
                    4) 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우<br/>
                    ③ 제2항 제2호 내지 제4호의 경우에 회사가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하지 않았다면 고객의 청약철회 등이 제한되지 않습니다.<br/>
                    ④ 고객은 제1항 및 제2항의 규정에도 불구하고 재화 등의 내용이 표시, 광고 내용과 다르거나 계약 내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다. 다만, 재화 등의 내용이 표시광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 회사가 부담합니다.<br/>
                    ⑤ 고객이 수취거부나 환불을 원하는 경우 배송비(처음 구매 건이 무료배송이면 왕복 배송비, 고객 선불이면 편도 배송비)와 은행 송금 수수료를 부담해야 하며, 실제 회사의 환불은 상품 반송이 확인 된 후 환불됩니다.<br/>
                    ⑥ 단순 고객변심으로 인한 교환/반품의 경우 왕복 배송비는 고객이 부담해야 합니다.<br/>
                    ⑦ 고객의 부재중이거나 수취인 주소 불명으로 인해 반송되어 다시 배송되는 경우 해당 배송비는 고객이 부담합니다.<br/>
                    ⑧ 모든 반품 시, 고객은 판촉행위로 인한 경품 및 사은품을 반납하여야 하며, 고객이 사은품을 사용하였을 경우 회사는 해당 금액만큼 차감하여 지급할 수 있습니다. 다만, 회사의 책임이 있는 사유로 인한 반품의 경우에는 그렇지 않습니다.

                </p>
            </article>
            <article>
                <h5>제 21 조 (웹사이트 연결 등에 관한 책임)</h5>
                <p>① 회사는 ‘당사이트 등’에 다른 사이트를 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등에 의해 연결시킬 수 있습니다.<br/>
                    ② 회사는 사이트에 연결된 다른 사이트가 독자적으로 제공하는 재화 등에 의하여 고객과 행하는 거래에 대해서 보증 책임을 지지 않는다는 뜻을 사이트의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증 책임을 지지 않습니다.

                </p>
            </article>
            <article>
                <h5>제 22 조 (정보의 제공 및 광고의 게재)</h5>
                <p>① "당사이트"는 "회원"이 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 "회원"에게 제공할 수 있습니다. 다만, "회원"은 관련법에 따른 거래관련정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신거절을 할 수 있습니다.<br />
                    ② ①항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 "회원"의 사전동의를 받아서 전송합니다. 다만, "회원"의 거래관련정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.<br />
                    ③ "당사이트"는 "서비스"의 운영과 관련하여 서비스화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 "회원"은 수신거절을 "당사이트"에 할 수 있습니다.<br />
                    ④ ③항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 "회원"의 사전동의를 받아서 전송합니다. 다만, "회원"의 거래관련정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.<br />
                    ⑤ 이용자(회원, 비회원포함)는 “당사이트”가 제공하는 서비스와 관련하여 게시물 또는 기타정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.
                </p>
            </article>
            <article>
                <h5>제 23 조 ("게시물"의 저작권)</h5>
                <p>① "회원"이 "서비스" 내에 게시한 "게시물"의 저작권은 해당 게시물의 저작자에게 귀속됩니다.<br />
                    ② "회원"이 "서비스" 내에 게시하는 "게시물"은 검색결과 내지 "서비스" 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다.<br />
                    이 경우, 회사는 저작권법규정을 준수하며, "회원"은 언제든지 고객센터 또는 "서비스" 내 관리기능을 통해 해당 게시물에 대해 삭제를 요청할 수 있습니다.<br />
                    ③ "당사이트"는 제2항 이외의 방법으로 "회원"의 "게시물"을 이용하고자 하는 경우에는 전화, 팩스, 전자우편을 통해 사전에 "회원"의 동의를 얻어야 합니다.
			</p>
            </article>
            <article>
                <h5>제 24 조 ("게시물"의 관리)</h5>
                <p>① "회원"의 "게시물"이 "정보통신망법" 및 "저작권법" 등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 "게시물"의 게시중단 및 삭제 등을 요청할 수 있으며, "당사이트"는 관련법에 따라 조치를 취하여야 합니다.<br />
                    ② "당사이트"는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될만한 사유가 있거나 약관, 운영정책, 관련법에 위반되는 경우에는 관련법에 따라 해당 "게시물"에 대해 임시조치 등을 취할 수 있습니다.<br />
                    ③ “당사이트”는 게시된 내용을 사전 통지 없이 편집, 이동할 수 있는 권리를 보유하며, 다음의 경우 사전 통지 없이 삭제할 수 있습니다.<br />
                    1) 본 서비스약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시한 경우|<br />
                    2) 다른 회원 또는 제 3자를 비방하거나 명예를 손상시키는 내용인 경우<br />
                    3) 공공질서 및 미풍양속에 위반되는 내용인 경우<br />
                    4) 범죄적 행위에 결부된다고 인정되는 내용인 경우<br />
                    5) 제3자의 저작권 등 기타권리를 침해하는 내용인 경우<br />
                    6) 본 약관 제 11조(회원의 의무) 및 운영정책에 위반되는 내용인 경우<br />
                    7) 기타관계법령에 위배되는 경우<br />
                    ④ “회원”의 게시물이 타인의 저작권을 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 해당 게시물을 게재한 “회원”이 부담하여야 합니다.
			</p>
            </article>
            <article>
                <h5>제 25 조 (권리의 귀속)</h5>
                <p>① "서비스"에 대한 저작권 및 지적재산권은 "당사이트"에 귀속됩니다. 단, "회원"의 "게시물" 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.
                ② "당사이트"는 서비스와 관련하여 "회원"에게 "당사이트"가 정한 이용조건에 따라 계정, "아이디", 콘텐츠, "포인트" 등을 이용할 수 있는 이용권만을 부여하며, "회원"은 이를 양도, 판매, 담보 제공 등의 처분 행위를 할 수 없습니다.
			</p>
            </article>
            <article>
                <h5>제 26 조 (포인트)</h5>
                <p>① 포인트는 회사의 정책에 따라 소진 및 적립이 가능하며 서비스의 효율적 이용 및 운영을 위해 정책을 변경할 수 있습니다.<br/>
                    ② 포인트는 회사가 정한 기간에 따라 주기적으로 부분적 혹은 완전 소멸할 수 있습니다.<br/>
                    ③ 포인트에 대한 구체적인 내용은 ‘당사이트 등’에 게재합니다.<br/>
                    ④ 포인트 정책은 아래와 같습니다.<br/>
                    1) 포인트는 광주-기아 챔피언스필드 유료 티켓 구입 이력에 따라 적립됩니다.<br/>
                    2) 포인트 1K는 유료 티켓 구입 1회 당 부여됩니다.<br/>
                    3) 포인트 적립에 따른 혜택 제공기간은 당해년도 KBO 정규리그 종료일까지이며, 사용하지 않는 포인트는 자동 소멸됩니다.<br/>
                    4) 포인트 및 등급은 매년 초기화 됩니다.<br/>
                    5) 회원 자격이 정지된 경우에는 포인트 사용이 제한될 수 있습니다.<br/>
                    6) 포인트 양도는 불가합니다.
                </p>
            </article>
            <article>
                <h5>제 27 조 (이용계약해지 및 탈퇴)</h5>
                <p>① 회원은 언제든지 서비스 초기화면의 고객센터 또는 내 정보 관리메뉴 등을 통하여 이용계약해지 신청을 할 수 있으며, 회사는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.<br/>
                    ② 회원이 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라 회원 정보를 일부 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 데이터는 소멸됩니다.<br/>
                    ③ 회원이 계약을 해지하는 경우, 회원이 작성한 게시물 중 1:1 문의내역 등과 같이 본인계정에 등록된 게시물 일체는 삭제됩니다. 다만, 타인에 의해 보관된 보관함이나 공용게시판에 등록된 게시물 등은 삭제되지 않으니 사전에 삭제 후 탈퇴하시기 바랍니다.<br/>
                    ④ 유료회원의 경우 가입금은 가입선물 및 각종혜택(배송비, 입장권할인, 이벤트참여, 컨텐츠 이용 등)으로 대체되었기에 환불이 불가합니다.<br/>
                    ⑤ 회원탈퇴 요청이 있을 시 현재 포인트 유무와 상관없이 회원탈퇴가 확정되고 회원자격도 상실됩니다.<br/>
                    ⑥ 회사의 이용약관 및 운영정책에 따라 이용계약이 해지된 회원 또는 이용제한 중인 회원, 본인이 해지한 회원은 재가입이 제한될 수 있습니다.<br/>
                    ⑦ 회사는 법령에서 정하는 기간 동안 회원이 ‘당사이트 등’을 이용하기 위해 로그인 혹은 접속한 기록이 없는 경우, 회원이 등록한 전자우편(E-mail) 주소, 문자메시지 등 기타 유효한 수단으로 통지 후 회원의 정보를 파기하거나 분리 보관할 수 있으며, 이로 인해 서비스 이용을 위한 필수적인 정보가 부족할 경우 이용계약이 해지될 수도 있습니다.<br/>
                    ⑧ 회사는 회원이 아래 기간 동안 서비스를 이용, 계정 로그인하지 않으면 개인정보 파기와 이용계약을 해지할 수 있습니다.<br/>
                    1) 1년 동안 ‘당사이트 등’ 의 로그인을 통해 어떠한 개별 서비스에서도 이용하지 않은 경우 이용계약 해지<br/>
                    2) 유료회원의 경우에도 1년 동안 ‘당사이트 등’의 로그인을 하지 않은 경우 유료회원 서비스 이용의사가 없는 것으로 간주하여 유료회원 자격 박탈 및 이용계약 해지<br/>
                    3) 상기 대상의 경우 로그인 기록이 1년 이상 없을 경우 이메일, 휴대전화 문자, 카카오톡 알림톡을 통하여 이용계약 해지 안내하고, 1년 초과 후 30일간 로그인하지 않은 경우 31일이 된 날에 이용계약 해지


                </p>
            </article>
            <article>
                <h5>제 28 조 (이용제한 등)</h5>
                <p>① 회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 회사는 회원의 본 서비스 사용을 일부 또는 전부 중지할 수 있으며, 이용계약해지 또는 영구 이용정지를 할 수 있습니다. 이로 인해 발생하는 불이익에 대해 책임을 부담하지 아니합니다.<br/>
                    ② 회사는 전항에도 불구하고, “주민등록법”을 위반한 명의도용 및 결제도용, “저작권법” 및 “컴퓨터프로그램보호법”을 위반한 불법프로그램의 제공 및 운영방해, “정보통신망법”을 위반한 불법 통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위등과 같이 관련법을 위반한 경우에는 즉시 이용계약해지 또는 영구이용정지를 할 수 있습니다.<br/>
                    ③ 회사는 이용약관 ‘제5조(회원가입) 제3항’에 따른 가입 승낙 취소 사유가 있는 것을 사후에 발견하였을 경우, 회원에게 이용계약을 해지할 수 있습니다.<br/>
                    ④ 회사는 원활한 서비스 제공을 위해 ‘당사이트 등’ 이용시 발생한 명예훼손, 모욕, 폭력(폭행) 등으로 법적 처벌을 받은 회원에 대해서는 즉시 영구이용정지를 하거나 이용계약을 해지할 수 있습니다.<br/>
                    ⑤ 제1항부터 제4항에 따른 이용계약해지 및 영구이용정지 시 서비스 이용을 통해 획득한 포인트 및 기타 혜택 등도 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.<br/>
                    ⑥ 회사는 이용자가 제공하는 정보의 내용이 허위인 것으로 판명되거나, 허위가 있다고 의심할만한 합리적인 사유가 발생할 경우 본 서비스 사용을 일부 또는 전부 중지할 수 있으며, 이로 인해 발생하는 불이익에 대해 회사는 책임을 부담하지 아니합니다.<br/>
                    ⑦ 회사는 회원이 본 약관 ‘제11조(회원의 의무)’ 등 본 약관의 내용에 위배되는 행동을 한 경우 서비스 사용을 제한하거나 이용계약을 해지할 수 있습니다.<br/>
                    ⑧ 선량한 이용자의 보호를 위하여 다음에 해당하는 이용자에 대하여 사전 통보 없이 일정한 기간을 정하여 예매 제한 또는 해당 예매 건에 대한 예매 취소 조치를 취할 수 있으며, 이 경우 발생하는 모든 책임은 이용자가 부담합니다.<br/>
                    1) 반복적인 다량구매 후 취소를 하는 경우(예: 재 판매를 위하여 일정기간 동안 다량 구매 후 취소를 반복하는 경우)<br/>
                    2) 연 30회이상 무통장 미입금 취소할 경우, 무통장입금결제에 한해 결제를 제한할 수 있습니다.<br/>
                    3) 비정상적인 구매(매크로 등 프로그램 이용, 반복적인 구매/취소 등)및 재 판매를 통해 재산상의 이익을 취득하고자 다른 이용자에게 피해를 주는 경우<br/>
                    ⑨ 상기 8항에 의해 예매제한 등의 조치가 취해진 경우 해당 이용자는 소명할 수 있으며, 1일 이상의 소명기한을 부여하여 이용자로부터 기한 내에 합리적인 소명서를 제출 받는 경우에는 예매제한 조치를 해지할 수 있습니다.<br/>
                    ⑩ 회사는 회원이 계속해서 1년간 로그인하지 않는 경우, 장기 미사용에 따른 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한하거나 정보를 완전 삭제할 수 있습니다.<br/>
                    ⑪ 본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 전자우편(E-mail)을 통해 해당회원에게 통지합니다.<br/>
                    ⑫ 회원은 서비스 이용제한에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 해당 조치를 철회할 수 있습니다.<br/>
                    ⑬ 회사에서 행하는 이용제한의 세부내용은 운영정책에서 정하는 바에 의합니다

                </p>
            </article>
            <article>
                <h5>제 29 조 (면책) </h5>
                <p>① 회사는 회사의 과실 없이 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.<br/>
                    1) 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우<br/>
                    2) 서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우<br/>
                    3) 회원의 귀책사유로 서비스 이용에 장애가 있는 경우<br/>
                    4) 제1호 내지 제3호를 제외한 기타 회사의 고의·과실이 없는 사유로 인한 경우<br/>
                    ② 회사는 회원이 작성하는 등의 방법으로 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며, 회사의 과실 없이 발생된 회원의 손해에 대해서는 책임을 부담하지 아니합니다.
                </p>
            </article>
            <article>
                <h5>제 30 조 (당사이트의 소유권)</h5>
                <p>“당사이트”가 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고, 디자인, 서비스명칭, 정보 및 상표등과 관련된 지적재산권 및 기타권리는 “당사이트”에 소유권이 있습니다. 귀하는 “당사이트” 가 명시적으로 승인한 경우를 제외하고는 전항의 소정의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재라이센스, 담보권설정행위, 상업적 이용행위를 할 수 없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없습니다.</p>
            </article>
            <article>
                <h5>제 31 조 (양도금지)</h5>
                <p>“회원”은 서비스의 이용권한, 기타이용 계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.</p>
            </article>
            <article>
                <h5>제 32조 (준거법 및 재판관할)</h5>
                <p>① "당사이트"와 "회원" 간 제기된 소송은 대한민국법을 준거법으로 합니다.<br />
                    ② "당사이트"와 "회원"간 발생한 분쟁에 관한 소송은 민사소송법상의 관할법원에 제소합니다.
			</p>
            </article>
            <article>
                <h5>부 칙</h5>
                <p>① 이 약관은 2025 년 1월 20일부터 적용됩니다.<br />
                    ② 2013년부터 시행되던 종전의 약관은 본 약관으로 대체됩니다.
			</p>
            </article>
        </div>
    </div>
)

export default ContServiceComponent;
