import React, {useEffect, useState} from "react";
import SubMenuComponent from "../common/SubMenu.container";
import Latest from "./content/Latest";
import Old200325 from "./content/Old200325";
import Old230228 from './content/Old230228';
import Old170330 from './content/Old170330';
import Old170516 from "./content/Old170516";
import Old180713 from "./content/Old180713";
import Old200210 from "./content/Old200210";
import Old200324 from "./content/Old200324";
import Old250119 from "./content/Old250119";

const ContPrivacyComponent = (props) => {
  const [version, setVersion] = useState("Latest");
  const [htmlContent, setHtmlContent] = useState(""); // HTML 콘텐츠 상태 추가
  const [isHtmlLoaded, setIsHtmlLoaded] = useState(false); // HTML 로딩 여부

  useEffect(() => {
    window.scrollTo(0, 0);

    if (version.startsWith("HTML")) {
      // HTML 파일을 로드할 경우
      const fileName = version.replace("HTML", ""); // 예: "HTML200325" → "200325.html"
      fetch(`/doc/${fileName}.html`)
        .then((response) => response.text())
        .then((html) => {
          setHtmlContent(html);
          setIsHtmlLoaded(true);
        })
        .catch((err) => {
          console.error("Error loading HTML:", err);
          setHtmlContent("<p>Error loading HTML file</p>");
        });
    } else {
      setIsHtmlLoaded(false); // HTML 파일을 로드하지 않으면 초기화
    }
  }, [version]);

  return (
    <div className="mb_cont sub_cont policy">
      <div className="mb_contIn">
        <SubMenuComponent subMenu={props.subMenu} />
        {isHtmlLoaded ? (
          // HTML 로드 시 렌더링
          <div
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        ) : (
          // 기존 컴포넌트 렌더링
          version === "Latest" ? (
            <Latest setVersion={setVersion} />
          ) : version === "Old200325" ? (
            <Old200325 setVersion={setVersion} />
          ) : version === "Old230228" ? (
            <Old230228 setVersion={setVersion} />
          ) : version === "Old170330" ? (
            <Old170330 setVersion={setVersion} />
          ) : version === "Old170516" ? (
            <Old170516 setVersion={setVersion} />
          ) : version === "Old180713" ? (
            <Old180713 setVersion={setVersion} />
          ) : version === "Old200210" ? (
            <Old200210 setVersion={setVersion} />
          ) : version === "Old200324" ? (
            <Old200324 setVersion={setVersion} />
          ) : version === "Old250119" ? (
            <Old250119 setVersion={setVersion} />
          ) : (
            <p>Invalid version</p>
          )
        )}
      </div>
    </div>
  );
};


// const ContPrivacyComponent = props => {
//   const [version, setVersion, setHtmlContent] = useState("Latest")
//
//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [version])
//
//   return (
//     <div className="mb_cont sub_cont policy">
//       <div className="mb_contIn">
//         <SubMenuComponent subMenu={props.subMenu}/>
//         useEffect(() => {
//         fetch('./content/Old2017.html') // HTML 파일 경로
//           .then((response) => response.text())
//           .then((html) => setHtmlContent(html))
//       }, []);
//
//       </div>
//     </div>
//   )
// };



export default ContPrivacyComponent;
